import React from "react";
import { StyleSheet } from "react-native";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
    IconButton,
    Checkbox,
    Card,
    Modal,
    InputAdornment,
    FormControlLabel,
    AppBar, Avatar, Grid, Paper, ListItemAvatar,
    ListItemIcon,
    Pagination, PaginationItem
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import MicIcon from '@mui/icons-material/Mic';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import UserProfileBasicController, {
    Props,
} from "./UserProfileBasicController";

import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Picker from "emoji-picker-react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
interface Item {
    heading: string;
    subHeading: string;
    checked: boolean;
}
interface BookingData {
    attributes: {
        sender_id: number,
        status: string,
        request_text: string,
        start_date: string,
        start_time: string,
        end_time: string,
        service_name: string,
        created_at: string,
        sender_full_name: string,
        address: string,
        photo: string,
        frequency: string
    }
}
export const configJSON = require("./config");
import {
    logo, bookingProfilePic, rescheduleLogo, confirmCheckLogo,
    location, timer, receipt, userIcon,
    vector,
    cardIcon,
    lockIcon,
    flagIcon,
    tickIcon,
    darkCard,
    plusIcon,
    watchIcon,
    closeIcon
} from "./assets"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import { styled } from "@mui/material/styles";
import Settings2 from "../../settings2/src/Settings2.web";

const StyledTab = styled(Tabs)({
    width: "100%",
    ".MuiTabs-flexContainer": {
        display: 'flex',
        flexWrap: "wrap",
    },
    "@media (max-width: 600px)": {
        ".MuiTabs-flexContainer": {
            flexDirection: "column",
            alignItems: "center",
        }
    }
});

const StyledTypo1 = styled(Typography)({
    width: "100%",
    "@media (max-width: 600px)": {
        textAlign: "center"
    }
});

const StyledTypo2 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "20px !important"
    }
});

const StyledTypo3 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "9.5px !important",
    }
});

const StyledTypo4 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
    }
});

const StyledTypo5 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
    }
});

const StyledTypo6 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "8px !important",
        position: "relative",
        top: "3px",
        minWidth: "41px !important",
    }
});

const StyledTypo7 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
        position: "relative",
        top: "5px"
    }
});

const StyledTypo8 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "8px !important",

    }
});

const StyledTypo9 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "16px !important",
        height: "19px !important"
    }
});

const StyledTypo10 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
    }
});

const StyledTypo11 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
    }
});

const StyledTypo12 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "15px !important",
    }
});

const StyledTypo13 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
    }
});

const StyledTypo14 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12.5px !important",
    }
});

const StyledTypo15 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "13.5px !important",
    }
});

const StyledTypo16 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
    }
});

const StyledTypo17 = styled(Typography)({
    "@media (max-width: 800px)": {
        maxWidth: "65vw !important"
    },
    "@media (max-width: 650px)": {
        maxWidth: "55vw !important"
    }
});

const StyledTypo18 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important"
    }
});

const StyledTypo19 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10.7px !important",
    },
});

const StyledTypo20 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "14px !important",
    },
});

const StyledTypo21 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "11.5px !important",
        maxWidth: "250px !important"
    },
});

const StyledTypo22 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
    },
});

const StyledTypo23 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "8px !important",
    },
});

const StyledTypo24 = styled(Typography)({
    "@media (max-width: 500px)": {
        height: "21px !important",
        width: "42px !important",
        borderRadius: '21px !important',
        padding: '2.8px 8.5px !important',
        fontSize: "8.5px !important",
        position: "relative",
        left: "10px",
    },
    "@media (max-width: 450px)": {
        position: "relative",
        left: "5px",
    },
    "@media (max-width: 400px)": {
        position: "relative",
        left: "0px",
    },
});

const StyledTypo25 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
        textAlign: "center"
    },
});

const StyledTypo26 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "8px !important",
        textAlign: "center"
    },
    "@media (min-width: 701px)": {
        display: "none"
    },
});

const StyledTypo27 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "11.3px !important",
    },
});

const StyledTypo28 = styled(Typography)({
    "@media (max-width: 500px)": {
        width: "138.35 !important",
        fontSize: "12px !important",
        color: "#000000 !important"
    },
});

const StyledTypo29 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "10.75px !important",
    },
});

const StyledPaper1 = styled(Paper)({
    "@media (max-width: 500px)": {
        padding: "10px 16px !important",
        maxWidth: "80%"
    },
});

const MainBox = styled(Box)({
    "@media (max-width: 500px)": {
        background: "#F1F5F9"
    }
});

const ConfirmedBox = styled(Box)({
    "@media (max-width: 500px)": {
        height: "20px !important",
        maxWidth: "75px !important",
        borderRadius: "20px !important",
        gap: "0px !important",
        padding: "0px 4px !important",
        boxSizing: "border-box"
    }
});

const ConfirmedIconButton = styled(IconButton)({
    "@media (max-width: 500px)": {
        padding: "8px 4px"
    }
});

const PopupIconButton = styled(IconButton)({
    "@media (max-width: 500px)": {
        height: "40px !important",
        width: "40px !important"
    }
});

const StyledIconButton1 = styled(IconButton)({
    "@media (max-width: 900px)": {
        left: "0px !important"
    }
});

const StyledBox1 = styled(Box)({
    width: "100%",
    flexWrap: "wrap",
    "@media (max-width: 500px)": {
        gap: "10px !important",
        flexDirection: "column",
    },
    "@media (max-width: 1050px)": {
        justifyContent: "center",
    },
    "@media (min-width: 1300px)": {
        gap: "40px !important",
    }
});

const StyledBox2 = styled(Box)({
    width: "100%",
    flexWrap: "wrap",
    "@media (max-width: 500px)": {
        flexDirection: "column",
        gap: "20px !important"
    },
    "@media (max-width: 850px)": {
        justifyContent: "center",
    }
});

const StyledBox3 = styled(Box)({
    "@media (max-width: 500px)": {
        width: "77px !important",
        height: "77px !important",
        alignSelf: "center"
    }
});
const StylesCard = styled(Box)({
    height: '48px',
    fontSize: '16px',
    display: 'flex',
    fontWeight: 600,
    gap: '131px',
    alignItems: 'center',
    "@media (max-width: 768px)": {
        gap: '0px',
    }
});

const StylesCard2 = styled(Box)({
    display: "flex",
    gap: "5%",
    "@media (max-width: 768px)": {
        gap: '20px',
        flexDirection: "column"
    }
});
const StylesCard5 = styled(Box)({
    flexDirection: "column",
    display: "flex",
    gap: "8px",
    maxWidth: "406px",
    width: "100%",
    "@media (max-width: 768px)": {
        textAlign: "center"
    }
});


const StylesCard3 = styled(Box)({
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    gap: "150px",
    alignItems: 'baseline',
    height: "48px",
    "@media (max-width: 768px)": {
        gap: '0px',
    }
});

const StylesCard4 = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "25px",
    paddingLeft: "55px",
    "@media (max-width: 768px)": {
        paddingLeft: "0px",
    }
});

const StyledBox4 = styled(Box)({
    "@media (max-width: 500px)": {
        padding: "20px !important"
    }
});

const StyledBox5 = styled(Box)({
    flexWrap: "wrap",
    "@media (max-width: 500px)": {
        alignItems: "center",
        flexDirection: "column"
    }
});

const StyledBox6 = styled(Box)({
    minHeight: "106px",
    "@media (max-width: 600px)": {
        background: "#FFFFFF"
    }
});

const StyledBox7 = styled(Box)({
    flexWrap: "wrap",
    "@media (max-width: 600px)": {
        justifyContent: "flex-start !important",
        paddingLeft: "10px",
        paddingBotton: "5px"
    }
});

const StyledBox8 = styled(Box)({
    "@media (max-width: 750px)": {
        gap: "0px !important",
        flexDirection: "column-reverse",
        alignItems: "flex-end !important"
    }
});

const StyledBox9 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "10px !important",

    }
});

const StyledBox10 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "10px !important",
    },
    "@media (max-width: 570px)": {
        flexDirection: "column-reverse",
        alignItems: "flex-end !important"
    }
});

const StyledBox11 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "15px !important",
    },
});

const StyledBox12 = styled(Box)({
    flexWrap: "wrap",
    "@media (max-width: 500px)": {
        gap: "30px",
    },
});

const StyledBox13 = styled(Box)({
    "@media (max-width: 500px)": {
        display: "flex !important"
    },
});

const StyledBox14 = styled(Box)({
    "@media (max-width: 500px)": {
        display: "none !important"
    },
});

const StyledBox15 = styled(Box)({
    "@media (max-width: 500px)": {
        borderBottom: "1px solid #D1D1D1 !important",
        height: "52px !important",
        background: "#FFFFFF",
        marginTop: "35px"
    },
});
const ModifiedCard = styled(Box)({
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    "@media (max-width: 1190px)": {
        maxWidth: '700px',
        width: "100%"
    },
});


const StyledBox16 = styled(Box)({
    "@media (max-width: 900px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "340px",
    },
});

const StyledBox17 = styled(Box)({
    "@media (max-width: 900px)": {
        bottom: "0px !important",
        alignItems: "flex-start !important"
    },
});

const StyledBox18 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "20px !important"
    },
});

const StyledBox19 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "10px !important"
    },
})

const StyledBox20 = styled(Box)({
    "@media (max-width: 500px)": {
        width: "310px !important"
    },
})

const StyledBox21 = styled(Box)({
    "@media (max-width: 500px)": {
        padding: "10px 0px 0px 90px !important"
    },
})

const StyledBox22 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "10px !important",
    },
})

const StyledBox23 = styled(Box)({
    "@media (max-width: 900px)": {
        gap: "15px !important"
    },
    "@media (max-width: 800px)": {
        flexDirection: "column",
        gap: "50px !important",
    },
})

const StyledBox24 = styled(Box)({
    "@media (max-width: 900px)": {
        maxHeight: "400px",
    },
})

const StyledBox25 = styled(Box)({
    "@media (max-width: 900px)": {
        paddingRight: "8px",
    },
    "@media (max-width: 800px)": {
        width: "100%",
    },
})

const StyledBox26 = styled(Box)({
    "@media (max-width: 650px)": {
        padding: "10px 5px !important",
        gap: "5px !important"
    },
})

const StyledBox27 = styled(Box)({
    "@media (max-width: 900px)": {
        width: "280px !important",
    },
    "@media (max-width: 500px)": {
        gap: "10px !important"
    },
})

const StyledBox28 = styled(Box)({
    "@media (max-width: 500px)": {
        backgroundColor: "#F1F5F9 !important",
        gap: "15px !important",
        padding: "0px 10px 20px 0px !important",
        marginTop: "40px",
    },
})

const StyledEarningsTableContainer = styled(TableContainer)({
    "@media (max-width: 500px)": {
        backgroundColor: "#FFFFFF",
        borderRadius: "8.5px",
    },
})

const StyledBox30 = styled(Box)({
    "@media (max-width: 500px)": {
        padding: "25px",
    },
})

const StyledBox31 = styled(Box)({
    "@media (max-width: 1200px)": {
        flexDirection: "column",
        gap: "60px",
        paddingLeft: "15px",
        flexGrow: "0 !important"
    },
})

const StyledBox32 = styled(Box)({
    "@media (max-width: 800px)": {
        marginRight: "0px !important"
    },
})

const StyledBox33 = styled(Box)({
    "@media (max-width: 750px)": {
        position: "relative",
        right: "50px"
    },
    "@media (max-width: 500px)": {
        position: "relative",
        right: "50px"
    },
})

const StyledBox34 = styled(Box)({
    "@media (max-width: 500px)": {
        gap: "10px !important"
    },
})

const StyledHeaderTableCellEarnings = styled(TableCell)({
    "@media (max-width: 500px)": {
        fontSize: "11.3px !important",
        padding: "5px",
        textAlign: "center"
    },
})

const StyledHeaderTableCellEarningsDate = styled(TableCell)({
    "@media (max-width: 700px)": {
        display: "none",
    },
})

const StyledHeaderTableCellEarningsServices = styled(TableCell)({
    "@media (max-width: 500px)": {
        fontSize: "11.3px !important",
        padding: "5px",
        textAlign: "center"
    },
})

const StyledRowTableCellEarnings = styled(TableCell)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
        padding: "0px",
        width: "50px",
        textAlign: "center",
    },
})

const StyledRowTableCellEarningsDate = styled(TableCell)({
    "@media (max-width: 700px)": {
        display: "none"
    },
})

const StyledRowTableCellEarningsId = styled(TableCell)({
    "@media (max-width: 500px)": {
        fontSize: "11.3px !important",
        padding: "0px",
        width: "50px",
        textAlign: "center",
    },
})

const StyledRowTableCellEarningsServices = styled(TableCell)({
    "@media (max-width: 500px)": {
        fontSize: "10px !important",
        padding: "0px",
        width: "50px",
    },
})

const StyledTableEarnings = styled(Table)({
    "@media (max-width: 500px)": {
        padding: "4px 6px"
    },
})

const StyledCard12 = styled(Card)({
    "@media (max-width: 500px)": {
        gap: "15px !important",
    },
});

const StyledCardReschedule = styled(Card)({
    "@media (max-width: 900px)": {
        height: "auto !important",
        padding: "0px 0px 10px 10px !important"
    },
});

const StyledRecheduleModal = styled(Modal)({
    "@media (max-width: 900px)": {
        paddingTop: "300px"
    },
    "@media (max-width: 800px)": {
        paddingTop: "350px"
    },
    "@media (max-width: 500px)": {
        paddingTop: "565px"
    },
});

const StyledDDISelect = styled(Select)({
    "@media (max-width: 500px)": {
        width: "311.81px !important",
        height: "35.33px !important",
        background: "#FFFFFF",
        borderRadius: "10px !important",
        "& .MuiInputBase-input": {
            fontSize: "12.3px"
        }
    }
});

const StyledDDITextField = styled(TextField)({
    "@media (max-width: 500px)": {
        width: "311.81px !important",
        height: "35.33px !important",
        background: "#FFFFFF",
        borderRadius: "10px !important",
        "& .MuiInputBase-input": {
            fontSize: "12.3px"
        }
    }
});

const StyledTextField = styled(TextField)({
    width: "335px",
    "@media (max-width: 400px)": {
        width: "294px !important",
        height: "33px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "10.7px"
        }
    }
});

const StyledTextFieldAddress = styled(TextField)({
    "@media (max-width: 400px)": {
        width: "294px !important",
        height: "33px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "10.7px"
        }
    }
});

const StyledTextFieldWnp = styled(TextField)({
    width: "360px",
    "@media (max-width: 400px)": {
        width: "292px !important",
        height: "40px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "11px"
        }
    }
});

const StyledSelectField = styled(Select)({
    width: "335px",
    "@media (max-width: 400px)": {
        width: "294px !important",
        height: "33px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "10.7px"
        }
    }
});

const StyledSelectField2 = styled(Select)({
    "@media (max-width: 500px)": {
        width: "70px !important",
        height: "18px !important",
        borderRadius: "6px !important",
        padding: "3px 5.5px !important",
        fontSize: "8px !important"
    }
});

const StyledSaveButton = styled(Button)({
    "@media (max-width: 500px)": {
        height: "28px !important",
        width: "117px !important",
        alignSelf: "flex-start",
        fontSize: "10.3px !important"
    }
});

const StyledMessageSendButton = styled(Button)({
    "@media (max-width: 650px)": {
        width: "150px !important",
    },
    "@media (max-width: 500px)": {
        width: "73px !important",
        height: "21px !important",
        fontSize: "10px !important"
    }
});

const ButtonBookingSeeMore = styled(Button)({
    "@media (max-width: 500px)": {
        alignSelf: "center !important"
    }
});

const StyledSaveWnpButton = styled(Button)({
    "@media (max-width: 500px)": {
        alignSelf: "center !important"
    }
});

const StyledButton1 = styled(Button)({
    "@media (max-width: 500px)": {
        width: "214px !important",
        height: "26px !important",
        borderRadius: "6px !important",
        padding: "7.5px 15px !important",
        fontSize: "10px !important",
        alignSelf: "center"
    }
});

const StyledEditButtonDDI = styled(Button)({
    "@media (max-width: 600px)": {
        width: "66px !important",
        height: "20px !important",
        borderRadius: "20px !important",
        fontSize: "8.25px !important",
    }
});

const StyledSaveButtonDDI = styled(Button)({
    "@media (max-width: 700px)": {
        width: "123px !important",
        height: "31px !important",
        fontSize: "14px !important",
    }
});

const UserImage = styled('img')({
    "@media (max-width: 500px)": {
        width: "50px",
        height: "50px",
        borderRadius: "2.7px"
    }
});

const StyledUserProfileImg = styled('img')({
    "@media (max-width: 500px)": {
        width: "77px !important",
        height: "77px !important",
    }
});

const UserImageInReschedule = styled('img')({
    "@media (max-width: 500px)": {
        width: "92px",
        height: "110px",
        borderRadius: "2.5px"
    }
});

const TimerImage = styled('img')({
    "@media (max-width: 750px)": {
        width: "8px !important",
        height: "8px !important",
    }
});

const TickImage = styled('img')({
    "@media (max-width: 500px)": {
        width: "13px !important",
        height: "13px !important",
    }
});

const StyledPicker = styled(Picker)({
    "@media (max-width: 500px)": {
        top: "610px !important"
    }
});

const StyledAvatar1 = styled(Avatar)({
    "@media (max-width: 500px)": {
        height: "48px !important",
        width: "48px !important"
    }
});

const StyledAvatar2 = styled(Avatar)({
    "@media (max-width: 500px)": {
        height: "42px !important",
        width: "43px !important"
    }
});

const CustomPagination = styled(Pagination)({
    '& .MuiPaginationItem-page': {
        fontSize: '16px',
        color: '#515B6F',
        fontWeight: 600,
        fontFamily: "Roboto",
        "@media (max-width: 500px)": {
            fontSize: "10.5px !important",
            minWidth: "5px !important"
        },
        '&.Mui-selected': {
            backgroundColor: '#5E70DD',
            color: '#FFFFFF',
            borderRadius: '8px',
            padding: "10px 12px",
            fontSize: '16px',
            fontWeight: 600,
            fontFamily: "Roboto",
            minWidth: "46px !important",
            minHeight: "46px !important",
            "@media (max-width: 500px)": {
                padding: "6.5px 8px !important",
                borderRadius: "5.27px",
                minWidth: "30px !important",
                minHeight: "30px !important"
            },
        },
    },
    '& .MuiPaginationItem-root': {
        minWidth: "32px !important",
        "@media (max-width: 500px)": {
            minWidth: "5px !important"
        },
    },
    '& .MuiPaginationItem-previous, .MuiPaginationItem-next': {
        color: '#25324B',
    },
    '& .MuiSvgIcon-root': {
        width: "15.8px",
        height: "15.8px"
    }
});

const AvailWrapper = styled(Box)({
    width: "100%",
    "@media (max-width: 768px)": {
    },
    "& .longText": {
        fontFamily: "Manrope",
        fontSize: "13.38px",
        fontWeight: 600,
        lineHeight: "21.4px",
        color: "#030303"
    },
    "& .mainBoxWrap": {
        display: "flex",
        width: "100%",
        gap: "60.76px",
        "@media (max-width: 768px)": {
            flexDirection: "column"
        },
    },
    "& .availText": {
        fontFamily: "Manrope",
        fontSize: "14.34px",
        fontWeight: 600,
        lineHeight: "22.94px", color: "#515B6F"
    },
    "& .selectBox": {
        maxWidth: "393.24px",
        width: "100%",
        height: "44.5px",
        borderRadius: "7.17px",
        border: "0.9px solid #D6DDEB",
        fontFamily: "Manrope",
        fontSize: "14.34px",
        fontWeight: 600,
        lineHeight: "22.94px",
        color: "#010101"
    },
    "& .commonDesign": {
        maxWidth: "393.24px",
        width: "100%",
        height: "115.03px",
        display: "flex",
        flexDirection: "column",
        gap: "8.96px",
        borderRadius: "7.17px",
        border: "0.9px solid #D6DDEB"
    },
    "& .commonText": {
        fontFamily: "Manrope",
        fontSize: "16.05px",
        fontWeight: 600,
        lineHeight: "25.68px",
        color: "#010101",
        paddingLeft: "39px"
    },
    "& .commonTimeText": {
        fontFamily: "Manrope",
        fontSize: "13.38px",
        fontWeight: 600,
        lineHeight: "21.4px"
    },
    "& .boxwrp": {
        width: "29%",
        "@media (max-width: 768px)": {
            width: "100%"
        },
    }
})
const SideWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "393.33px",
    width: "100%",
    "& .sideBox": {
        maxWidth: "393.24px",
        width: "100%"
    },
    "& .befText": {
        fontFamily: "Manrope",
        fontSize: "16.05px",
        fontWeight: 600,
        lineHeight: "21.92px",
        color: "#272727"
    },
    "& .slect": {
        maxWidth: "179.23px",
        width: "100%",
        height: "41.46px"
    },
    "& .cancelBtn": {
        maxWidth: "135.9px",
        width: "100%",
        height: "40.61px",
        borderRadius: "9.44px",
        border: "0.94px solid #5E70DD",
        color: "#5E70DD",
        fontFamily: "Manrope",
        fontSize: "15.11px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: "24.18px"
    },
    "& .DateBtn": {
        width: "210.48px",
        height: "40.11px",
        borderRadius: "9.44px",
        fontFamily: "Manrope",
        fontSize: "15.11px",
        fontWeight: 700,
        lineHeight: "24.18px",
        backgroundColor: "#5E70DD !important",
        color: "#FFFFFF",
        textTransform: "capitalize"
    }
})


const TableComponent = styled(Box)({
    maxWidth: "1126px",
    width: "100%",

    "@media (max-width: 768px)": {
        gap: '0px',
    },
    "& .tableHeadText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        letterSpacing: "0.01em",
        color: "#131313"
    },
    "& .tableText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        letterSpacing: "0.01em"
    },
    "& .paidBtn": {
        maxWidth: "60px",
        width: "100%",
        height: "30px",
        borderRadius: "30px",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "21px",
        color: "#FFFFFF",
        backgroundColor: "#5E70DD !important",
        textTransform: "capitalize"

    },
    "& .rejectBtn": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "21px",
        color: "#FFFFFF",
        backgroundColor: "#FF9740 !important",
        maxWidth: "76px",
        width: "100%",
        height: "30px",
        borderRadius: "30px",
        textTransform: "capitalize"
    },
    "& .rowNameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "21.86px",
        color: "#11141A"
    },
    "& .rowTextTypo": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        color: "#11141A"
    }
});

const ModalContainer = styled(Box)({
    maxWidth: "908px",
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: " 0px 8px 32px 0px #0000000F",
    "@media (max-width: 768px)": {
        width: "95%"
    },
    "& .btnAlignment": {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "29px",
        paddingBottom: "35px",
        "@media (max-width: 768px)": {
            justifyContent: "center",
        },
    },
    "& .typoTitle": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.11px",
        color: "#0F172A",
        paddingBottom: "21px"
    },
    "& .greyText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#707070"
    },
    "& .blackText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        color: "#121212",
    },
    "& .contentWrapper": {
        maxWidth: "310px",
        width: "100%",
        display: "flex",
        gap: "14px",
        flexDirection: "column"
    },
    "& .credText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#FF9746"
    },
    "& .credBtn": {
        maxWidth: "292px",
        width: "100%",
        height: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "capitalize"
    },
    "& .mainWrapper": {
        display: "flex",
        gap: "59.92px",
        "@media (max-width: 678px)": {
            flexDirection: "column"
        }
    },
    "& .modWrap": {
        paddingLeft: "40px",
        "@media (max-width: 678px)": {
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            paddingLeft: "0px"
        },
    }
})

const earningsData = [
    { id: 10001, date: 'Oct 15, 2024', service: 'Tech Help', amount: '$100.00', status: 'Paid' },
    { id: 10002, date: 'Oct 16, 2024', service: 'Outdoor Help', amount: '$200.00', status: 'Paid' },
    { id: 10003, date: 'Oct 17, 2024', service: 'Tech Help', amount: '$300.00', status: 'Paid' },
    { id: 10004, date: 'Oct 18, 2024', service: 'Outdoor Help', amount: '$400.00', status: 'Paid' },
    { id: 10005, date: 'Oct 19, 2024', service: 'Indoor Help', amount: '$500.00', status: 'Rejected' },
    { id: 10006, date: 'Oct 20, 2024', service: 'Tech Help', amount: '$600.00', status: 'Paid' },
    { id: 10007, date: 'Oct 21, 2024', service: 'Tech Help', amount: '$700.00', status: 'Rejected' },
    { id: 10008, date: 'Oct 22, 2024', service: 'Tech Help', amount: '$800.00', status: 'Paid' },
    { id: 10009, date: 'Oct 23, 2024', service: 'Outdoor Help', amount: '$900.00', status: 'Paid' },
    { id: 10010, date: 'Oct 24, 2024', service: 'Tech Help', amount: '$1000.00', status: 'Paid' },
    { id: 10011, date: 'Oct 25, 2024', service: 'Outdoor Help', amount: '$1100.00', status: 'Paid' },
    { id: 10012, date: 'Oct 26, 2024', service: 'Indoor Help', amount: '$1200.00', status: 'Rejected' },
    { id: 10013, date: 'Oct 27, 2024', service: 'Tech Help', amount: '$1300.00', status: 'Paid' },
    { id: 10014, date: 'Oct 28, 2024', service: 'Tech Help', amount: '$1400.00', status: 'Rejected' },
    { id: 10015, date: 'Oct 29, 2024', service: 'Tech Help', amount: '$1500.00', status: 'Paid' },
    { id: 10016, date: 'Oct 30, 2024', service: 'Tech Help', amount: '$1600.00', status: 'Rejected' },
];

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);

    }

    renderWorkPreferencesAndInterests() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <StyledTypo2 style={{
                        width: "fit-content", height: "34px", color: "#25324B", fontFamily: "Manrope",
                        fontSize: "24px", fontWeight: 400
                    }}>
                        What are you good at?
                    </StyledTypo2>
                    {this.state.showWnpEditButton && (
                        <Typography style={{
                            width: "fit-content", height: "19px", color: "#006AFF",
                            fontWeight: 400, fontFamily: "Manrope",
                            cursor: "pointer", fontSize: "14px",
                        }}
                            data-test-id="editWnpTestBtn"
                            onClick={() => this.handleEditWnpButton()}
                        > Edit
                        </Typography>
                    )}
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    {
                        configJSON.workPreferencesAndInterestsData.map((lable: Item, keyData: number) => (
                            <Box style={{
                                display: "flex", gap: "10px",
                                alignItems: "center", justifyContent: "flex-start"
                            }}>
                                <Checkbox
                                    data-test-id="workCheckboxTestBtn"
                                    checked={this.handleChecks(keyData)}
                                    onChange={(event) => this.handleWnpCheckbox(event, keyData)}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: "#5E70DD"
                                        },
                                    }}
                                    style={{ border: "1px solid #D1D1D6", height: "20px", width: "20px", borderRadius: "4px" }}
                                />
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography style={{
                                        width: "fit-content", color: "#252729", fontFamily: "Manrope",
                                        fontSize: "16px", fontWeight: 600
                                    }}>
                                        {lable.heading}
                                    </Typography>
                                    <Typography style={{
                                        width: "fit-content", color: "#818486", fontFamily: "Manrope",
                                        fontSize: "12px", fontWeight: 400
                                    }}>
                                        {lable.subHeading}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <StyledTypo9 style={{
                        width: "fit-content", height: "29px", color: "#25324B",
                        fontSize: "24px", fontWeight: 400, fontFamily: "Manrope",
                    }}>
                        School Recommendations
                    </StyledTypo9>
                    <StyledBox12 style={{ display: "flex" }}>
                        <Box style={{
                            display: "flex", flexDirection: "column",
                            gap: "15px", flexGrow: 1
                        }}>
                            <StyledTypo10 style={{
                                width: "fit-content", color: "#25324B",
                                fontSize: "16px", fontWeight: 400, fontFamily: "Manrope",
                            }}>
                                Teacher or Coach Recommender #1
                            </StyledTypo10>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B",
                                fontSize: "14px", fontWeight: 400, fontFamily: "Manrope",
                            }}>
                                Full Name
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="fullNameWnp1TestBtn"
                                disabled={this.state.showWnpEditButton}
                                type="text"
                                style={{
                                    height: "56px", borderRadius: "10px",
                                    border: "1px solid #CBD5E1", justifyContent: "center",
                                    textAlign: "center",
                                }}
                                sx={{
                                    input: {
                                        color: "#3B3B3B", fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope"
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                placeholder="Full Name"
                            >
                            </StyledTextFieldWnp>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B",
                                fontSize: "14px", fontWeight: 400, fontFamily: "Manrope",
                            }}>
                                School Email
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="schoolEmailWnp1TestBtn"
                                type="text"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    border: "1px solid #CBD5E1", justifyContent: "center",
                                    height: "56px", borderRadius: "10px",
                                    textAlign: "center",
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope", color: "#3B3B3B",
                                    },
                                }}
                                placeholder="School Email"
                            >
                            </StyledTextFieldWnp>
                        </Box>
                        <Box style={{
                            display: "flex", flexGrow: 2,
                            flexDirection: "column", gap: "15px"
                        }}>
                            <StyledTypo10 style={{
                                fontSize: "16px", fontWeight: 400, fontFamily: "Manrope",
                                width: "fit-content", color: "#25324B",
                            }}>
                                Teacher or Coach Recommender #2
                            </StyledTypo10>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B", fontFamily: "Manrope",
                                fontSize: "14px", fontWeight: 400,
                            }}>
                                Full Name
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                disabled={this.state.showWnpEditButton}
                                data-test-id="fullNameWnp2TestBtn"
                                type="text"
                                style={{
                                    border: "1px solid #CBD5E1", textAlign: "center",
                                    height: "56px", borderRadius: "10px",
                                    justifyContent: "center",
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        fontSize: "16px", fontFamily: "Manrope",
                                        color: "#3B3B3B", fontWeight: 400,
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                }}
                                placeholder="Full Name"
                            >
                            </StyledTextFieldWnp>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B", fontWeight: 400,
                                fontSize: "14px", fontFamily: "Manrope",
                            }}>
                                School Email
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                type="text"
                                data-test-id="schoolEmailWnp1TestBtn"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    height: "56px", borderRadius: "10px",
                                    textAlign: "center",
                                    border: "1px solid #CBD5E1", justifyContent: "center",
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    input: {
                                        fontWeight: 400, fontSize: "16px",
                                        fontFamily: "Manrope", color: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                }}
                                placeholder="School Email"
                            >
                            </StyledTextFieldWnp>
                        </Box>
                    </StyledBox12>
                    <StyledTypo9 style={{
                        width: "fit-content", height: "29px", color: "#25324B",
                        fontSize: "24px", fontWeight: 400, fontFamily: "Manrope",
                    }}>
                        Parents Information
                    </StyledTypo9>
                    <StyledBox12 style={{ display: "flex" }}>
                        <Box style={{
                            display: "flex", flexDirection: "column",
                            gap: "15px", flexGrow: 1
                        }}>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B", fontWeight: 400,
                                fontSize: "14px", fontFamily: "Manrope",
                            }}>
                                Full Name
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="fullNameParentInfoWnpTestBtn"
                                type="text"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    height: "56px", justifyContent: "center",
                                    textAlign: "center",
                                    border: "1px solid #CBD5E1", borderRadius: "10px",
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope",
                                        color: "#3B3B3B",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                }}
                                placeholder="Full Name"
                            >
                            </StyledTextFieldWnp>
                            <StyledTypo11 style={{
                                width: "fit-content", fontWeight: 400,
                                fontSize: "14px", color: "#25324B", fontFamily: "Manrope",
                            }}>
                                School Email
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                disabled={this.state.showWnpEditButton}
                                data-test-id="schoolEmailParentInfoWnpTestBtn"
                                type="text"
                                style={{
                                    border: "1px solid #CBD5E1",
                                    height: "56px", borderRadius: "10px",
                                    justifyContent: "center", textAlign: "center",
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        fontWeight: 400, fontFamily: "Manrope",
                                        fontSize: "16px", color: "#3B3B3B",
                                    },
                                }}
                                placeholder="School Email"
                            >
                            </StyledTextFieldWnp>
                        </Box>
                        <Box style={{
                            flexDirection: "column", gap: "15px",
                            display: "flex", flexGrow: 2,
                        }}>
                            <StyledTypo11 style={{
                                fontSize: "14px", fontWeight: 400, fontFamily: "Manrope",
                                width: "fit-content", color: "#25324B",
                            }}>
                                Phone Number
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="phoneParnetInfoWnpTestBtn"
                                type="text"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    border: `${this.handleErrorBorder3()}`, textAlign: "center",
                                    height: "56px", borderRadius: "10px",
                                    justifyContent: "center",
                                }}
                                sx={{
                                    input: {
                                        fontSize: "16px", fontFamily: "Manrope",
                                        color: "#3B3B3B", fontWeight: 400,
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                }}
                                onChange={(event) => this.handleParentInfoPhoneNumberWnp(event)}
                                placeholder="Phone Number"
                            >
                            </StyledTextFieldWnp>
                            {
                                !this.state.isPhoneNumberValidParentInfoWnp &&
                                <Typography style={{
                                    width: "fit-content", textAlign: "start", color: "#DC2626",
                                    fontFamily: "Inter", fontWeight: 400, fontSize: "12px"
                                }}
                                > {this.cellNumberHelperText2()}
                                </Typography>
                            }
                        </Box>
                    </StyledBox12>
                    <StyledTypo9 style={{
                        width: "fit-content", height: "29px", color: "#25324B",
                        fontSize: "24px", fontWeight: 400, fontFamily: "Manrope",
                    }}>
                        School Representative
                    </StyledTypo9>
                    <StyledBox12 style={{ display: "flex" }}>
                        <Box style={{
                            gap: "15px", flexGrow: 1, flexDirection: "column",
                            display: "flex",
                        }}>
                            <StyledTypo11 style={{
                                width: "fit-content", color: "#25324B",
                                fontSize: "14px", fontWeight: 400, fontFamily: "Manrope",
                            }}>
                                Staff Member Full Name
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="fullNameSchoolRepTestBtn"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    height: "56px", borderRadius: "10px",
                                    border: "1px solid #CBD5E1", textAlign: "center",
                                    justifyContent: "center",
                                }}
                                type="text"
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        color: "#3B3B3B", fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope"
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                }}
                                placeholder="Name"
                            >
                            </StyledTextFieldWnp>
                            <StyledTypo11 style={{
                                color: "#25324B",
                                fontSize: "14px", fontWeight: 400,
                                fontFamily: "Manrope", width: "fit-content",
                            }}>
                                Staff Member Work Email
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="emailSchoolRepTestBtn"
                                type="text"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    border: "1px solid #CBD5E1", justifyContent: "center",
                                    height: "56px", textAlign: "center",
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    input: {
                                        fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope",
                                        color: "#3B3B3B",
                                    },
                                }}
                                placeholder="Email"
                            >
                            </StyledTextFieldWnp>
                        </Box>
                        <Box style={{
                            flexDirection: "column", gap: "15px",
                            display: "flex", flexGrow: 2,
                        }}>
                            <StyledTypo11 style={{
                                width: "fit-content",
                                fontSize: "14px", fontWeight: 400,
                                color: "#25324B", fontFamily: "Manrope",
                            }}>
                                Staff Member Title
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                disabled={this.state.showWnpEditButton}
                                data-test-id="titleSchoolRepTestBtn"
                                type="text"
                                style={{
                                    height: "56px", borderRadius: "10px",
                                    justifyContent: "center", textAlign: "center",
                                    border: "1px solid #CBD5E1",
                                }}
                                sx={{
                                    input: {
                                        color: "#3B3B3B",
                                        fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                }}
                                placeholder="Title"
                            >
                            </StyledTextFieldWnp>
                            <StyledTypo11 style={{
                                width: "fit-content",
                                color: "#25324B", fontWeight: 400,
                                fontSize: "14px", fontFamily: "Manrope",
                            }}>
                                Staff Member Work Phone
                            </StyledTypo11>
                            <StyledTextFieldWnp
                                data-test-id="phoneSchoolRepTestBtn"
                                type="text"
                                disabled={this.state.showWnpEditButton}
                                style={{
                                    height: "56px", borderRadius: "10px",
                                    border: `${this.handleErrorBorder4()}`, justifyContent: "center",
                                    textAlign: "center",
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#3B3B3B",
                                    },
                                    input: {
                                        fontWeight: 400, fontSize: "16px",
                                        fontFamily: "Manrope", color: "#3B3B3B",
                                    },
                                }}
                                onChange={(event) => this.handleSchoolRepPhoneNumberWnp(event)}
                                placeholder="Phone"
                            >
                            </StyledTextFieldWnp>
                            {
                                !this.state.isPhoneNumberValidSchoolRepWnp &&
                                <Typography style={{
                                    width: "fit-content", textAlign: "start", color: "#DC2626",
                                    fontFamily: "Inter", fontWeight: 400, fontSize: "12px"
                                }}
                                > {this.cellNumberHelperText3()}
                                </Typography>
                            }
                        </Box>
                    </StyledBox12>
                </Box>
                {this.state.showWnpSaveButton && (
                    <StyledSaveWnpButton
                        data-test-id="saveWnpTestBtn"
                        style={{
                            background: "#5E70DD", width: "202px", height: "41px",
                            borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                            textTransform: "none", alignSelf: "flex-end",
                            fontFamily: "Roboto", fontWeight: 700, fontSize: "16px",
                        }}
                        onClick={() => this.handleSaveWnpButton()}
                    > Save
                    </StyledSaveWnpButton>
                )}
            </Box>
        )
    }

    renderBookings() {
        dayjs.extend(updateLocale);
        dayjs.updateLocale('en', {
            weekStart: 1,
        });

        const getCurrRequestSentOrReceviedData = this.state.seeMoreClicked ?
            this.state.requestSentOrReceviedData :
            this.state.requestSentOrReceviedData.slice(0, 2);

        return (
            <>
                <Box style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "20px" }}>
                    {this.state.userType !== "Hire A Teen" && (
                        <Box style={{ display: "flex", gap: "40px", alignItems: "center" }}>
                            <StyledTypo3 style={{
                                width: "fit-content", height: "26px",
                                fontFamily: "Manrope", fontSize: "16px", fontWeight: 600,
                                cursor: "pointer", color: `${this.handleColorAllBookings()}`,
                            }}
                                data-test-id="allBookingAndChooseAvailabilityTestBtn1"
                                onClick={() => this.handleAllBookingAndChooseAvailability("All Bookings")}
                            >
                                All Bookings
                            </StyledTypo3>
                            <StyledTypo3 style={{
                                fontSize: "16px", fontWeight: 600, fontFamily: "Manrope",
                                width: "fit-content", height: "26px",
                                cursor: "pointer", color: `${this.handleColorChooseAvailability()}`
                            }}
                                data-test-id="allBookingAndChooseAvailabilityTestBtn2"
                                onClick={() => this.handleAllBookingAndChooseAvailability("Choose Availability")}
                            >
                                Choose Availability
                            </StyledTypo3>
                        </Box>
                    )}
                    {this.state.openAllBookingsOrChooseAvailability == "All Bookings" &&
                        this.state.userType == "Hire A Teen" && (
                            <StyledBox5 style={{ display: "flex", gap: "15px" }}>
                                <Select
                                    data-test-id="bookingStateDropTestBtn"
                                    value={this.state.bookingStateDropdown}
                                    style={{
                                        width: "162px", height: "40px", borderRadius: "8px",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        fontSize: "15px", color: "#252729", cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        "@media (max-width: 500px)": {
                                            width: "100px !important", height: "25px !important",
                                            fontSize: "9px !important",
                                        },
                                    }}
                                    onChange={(event) => this.handleBookingStateDropdown(event)}
                                    displayEmpty
                                >
                                    <MenuItem disabled value="">
                                        <Typography style={{
                                            fontSize: "15px", color: "#161C2D",
                                            fontFamily: "Manrope", fontWeight: 400,
                                            textOverflow: "ellipsis", overflow: "hidden",
                                            whiteSpace: "nowrap"
                                        }}>
                                            Booking State
                                        </Typography>
                                    </MenuItem>
                                    {
                                        this.state.bookingStateFilterData?.map((item, key) => (
                                            <MenuItem key={key} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                <Select
                                    data-test-id="serviceStateDropTestBtn"
                                    style={{
                                        fontSize: "15px", color: "#252729", cursor: "pointer",
                                        width: "156px", height: "40px", borderRadius: "8px",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        "@media (max-width: 500px)": {
                                            width: "100px !important", height: "25px !important",
                                            fontSize: "9px !important",
                                        },
                                    }}
                                    onChange={(event) => this.handleServiceDropdown(event)}
                                    value={this.state.serviceDropdown}
                                    displayEmpty
                                >
                                    <MenuItem disabled value="">
                                        <Typography style={{
                                            fontSize: "15px", color: "#161C2D",
                                            fontFamily: "Manrope", fontWeight: 400,
                                            textOverflow: "ellipsis", overflow: "hidden",
                                            whiteSpace: "nowrap"
                                        }}>
                                            Service
                                        </Typography>
                                    </MenuItem>
                                    {
                                        this.state.serviceFilterData?.map((item, key) => (
                                            <MenuItem key={key} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                <Select
                                    data-test-id="teenStateDropTestBtn"
                                    value={this.state.teenDropdown}
                                    style={{
                                        width: "175px", height: "40px", borderRadius: "8px",
                                        fontSize: "15px", color: "#252729", cursor: "pointer",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        "@media (max-width: 500px)": {
                                            width: "100px !important", height: "25px !important",
                                            fontSize: "9px !important",
                                        },
                                    }}
                                    onChange={(event) => this.handleTeenDropdown(event)}
                                    displayEmpty
                                >
                                    <MenuItem disabled value="">
                                        <Typography style={{
                                            fontSize: "15px", color: "#161C2D",
                                            fontFamily: "Manrope", fontWeight: 400,
                                            textOverflow: "ellipsis", overflow: "hidden",
                                            whiteSpace: "nowrap"
                                        }}>
                                            Student
                                        </Typography>
                                    </MenuItem>
                                    {
                                        this.state.teenFilterData?.map((item: any, key) => (
                                            <MenuItem key={key} value={item.teen_name}>
                                                {item.teen_name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </StyledBox5>
                        )}
                    {this.state.openAllBookingsOrChooseAvailability == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <StyledTypo4 style={{
                                fontSize: "24px", color: "#101928", fontFamily: "Manrope",
                                width: "fit-content", height: "33px", fontWeight: 600,
                            }}>
                                Upcoming
                            </StyledTypo4>
                            {this.state.upcomingData?.map((item: BookingData, keyData: number) => (
                                <StyledBox6 style={{
                                    display: "flex", borderRight: "2px solid #5E70DD",
                                    height: "auto", width: "100%", borderRadius: "8px",
                                    justifyContent: " space-between"
                                }}>
                                    <StyledBox7 style={{ display: "flex", gap: "20px" }}>
                                        <Box style={{
                                            display: "flex", padding: "12px",
                                            gap: "12px", width: "fit-content"
                                        }}>
                                            <UserImage
                                                src={item.attributes.photo}
                                                style={{
                                                    width: "78px", height: "71px",
                                                    borderRadius: "8px"
                                                }}
                                                alt="profile_pic"
                                            />
                                            <Box style={{
                                                flexDirection: "column",
                                                display: "flex", justifyContent: "center",
                                            }}>
                                                <Typography style={{
                                                    fontSize: "10px", fontWeight: 400,
                                                    fontFamily: "Manrope", width: "fit-content", color: "#11141A",
                                                }}>
                                                    {item.attributes.start_date}
                                                </Typography>
                                                <Typography style={{
                                                    width: "fit-content",
                                                    fontSize: "16px", fontFamily: "Manrope",
                                                    color: "#11141A", fontWeight: 700,
                                                }}>
                                                    {item.attributes.sender_full_name}
                                                </Typography>
                                                <Typography style={{
                                                    fontFamily: "Manrope",
                                                    width: "fit-content", fontWeight: 400,
                                                    fontSize: "14px", color: "#11141A"
                                                }}>
                                                    {item.attributes.service_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {this.state.userType != "Hire A Teen" && (
                                            <StyledBox7 style={{
                                                flexDirection: "column", gap: "6px", alignItems: "start",
                                                display: "flex", justifyContent: "center",
                                            }}>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", }}>
                                                    <LocationOnIcon style={{ width: "15px", height: "15px", }} />
                                                    <StyledTypo5 style={{
                                                        color: "#161C2D", fontWeight: 400,
                                                        fontSize: "14px", width: "fit-content",
                                                        fontFamily: "Manrope",
                                                    }}>
                                                        {item.attributes.address}
                                                    </StyledTypo5>
                                                </Box>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", }}>
                                                    <TimerImage
                                                        src={timer.default}
                                                        style={{ height: "15px", width: "15px" }}
                                                    />
                                                    <StyledTypo7 style={{
                                                        width: "fit-content", height: "21px",
                                                        fontSize: "15px",
                                                        color: "#161C2D", fontWeight: 400,
                                                    }}>
                                                        {item.attributes.frequency}
                                                    </StyledTypo7>
                                                </Box>
                                            </StyledBox7>
                                        )}
                                    </StyledBox7>
                                    <StyledBox8 style={{
                                        display: "flex", justifyContent: "space-evenly",
                                        alignItems: "center", paddingRight: "20px", gap: "20px"
                                    }}>
                                        <StyledBox9 style={{ display: "flex", alignItems: "center", gap: "35px" }}>
                                            <Box style={{
                                                gap: "2px", alignItems: "center",
                                                display: "flex", flexDirection: "column",
                                            }}
                                                data-test-id="messageNavigationTestBtn1"
                                                onClick={() => this.handleMessageNavigation()}
                                            >
                                                <PopupIconButton style={{
                                                    padding: "12px 24px", background: "#F7F8FA",
                                                    height: "60px", width: "60px",
                                                    borderRadius: "10px",
                                                }}>
                                                    <MailRoundedIcon style={{ color: "#11141A" }} />
                                                </PopupIconButton>
                                                <StyledTypo8 style={{
                                                    width: "60px", height: "21px",
                                                    color: "#11141A", fontFamily: "Manrope",
                                                    fontSize: "12px", fontWeight: 400, textAlign: "center"
                                                }}
                                                    sx={{
                                                        "@media (max-width: 500px)": {
                                                            width: "41px !important",
                                                            height: "15px !important"
                                                        },
                                                    }}
                                                >
                                                    Message
                                                </StyledTypo8>
                                            </Box>
                                            {this.rescheduleIcon1()}
                                            {this.cancelIcon1()}
                                            {this.declineIcon1()}
                                        </StyledBox9>
                                        <ConfirmedBox style={{
                                            display: "flex", background: "#5E70DD", alignItems: "center",
                                            height: "30px", maxWidth: "109px", width: "100%",
                                            borderRadius: "30px", gap: "8px", padding: "0px 8px",
                                        }}>
                                            <ConfirmedIconButton style={{ padding: "0px" }}>
                                                <TickImage
                                                    src={confirmCheckLogo.default}
                                                    alt="reschedule"
                                                    style={{ height: "21px", width: "21px" }}
                                                />
                                            </ConfirmedIconButton>
                                            <StyledTypo6 style={{
                                                width: "100%", minWidth: "66px",
                                                height: "21px", color: "#FFFFFF", fontFamily: "Manrope",
                                                fontSize: "12px", fontWeight: 600, textAlign: "center"
                                            }}>
                                                Confirmed
                                            </StyledTypo6>
                                        </ConfirmedBox>
                                    </StyledBox8>
                                </StyledBox6>
                            ))}
                        </Box>
                    )}
                    {this.state.openAllBookingsOrChooseAvailability == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                width: "fit-content", height: "33px", fontFamily: "Manrope",
                                fontSize: "24px", fontWeight: 600, color: "#101928",
                            }}>
                                {this.pendingOrReceivedRequests()}
                            </Typography>
                            {getCurrRequestSentOrReceviedData?.map((item: BookingData, keyData: number) => (
                                <StyledBox6 style={{
                                    display: "flex", borderRight: "2px solid #5E70DD",
                                    height: "auto", width: "100%", borderRadius: "8px",
                                    justifyContent: "space-between",
                                }}>
                                    <StyledBox7 style={{ display: "flex", gap: "20px" }}>
                                        <Box style={{
                                            display: "flex", padding: "12px",
                                            gap: "12px", width: "fit-content"
                                        }}>
                                            <img
                                                src={item.attributes.photo}
                                                style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                                alt="profile_pic"
                                            />
                                            <Box style={{
                                                flexDirection: "column",
                                                display: "flex", justifyContent: "center",
                                            }}>
                                                <Typography style={{
                                                    fontSize: "10px", fontWeight: 400,
                                                    fontFamily: "Manrope",
                                                    width: "fit-content", color: "#11141A",
                                                }}>
                                                    {item.attributes.start_date}
                                                </Typography>
                                                <Typography style={{
                                                    fontSize: "16px", fontFamily: "Manrope",
                                                    width: "fit-content",
                                                    color: "#11141A", fontWeight: 700,
                                                }}>
                                                    {item.attributes.sender_full_name}
                                                </Typography>
                                                <Typography style={{
                                                    fontFamily: "Manrope",
                                                    fontSize: "14px", color: "#11141A",
                                                    width: "fit-content", fontWeight: 400,
                                                }}>
                                                    {item.attributes.service_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {this.state.userType != "Hire A Teen" && (
                                            <StyledBox7 style={{
                                                alignItems: "start", display: "flex", justifyContent: "center",
                                                flexDirection: "column", gap: "6px",
                                            }}>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                    <StyledTypo5 style={{
                                                        color: "#161C2D", fontWeight: 400, fontSize: "14px",
                                                        width: "fit-content", fontFamily: "Manrope",
                                                    }}>
                                                        {item.attributes.address}
                                                    </StyledTypo5>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <TimerImage style={{ height: "15px", width: "15px" }}
                                                        src={timer.default}
                                                    />
                                                    <StyledTypo7 style={{
                                                        color: "#161C2D", fontWeight: 400, fontSize: "15px",
                                                        width: "fit-content", height: "21px",
                                                    }}>
                                                        {item.attributes.frequency}
                                                    </StyledTypo7>
                                                </Box>
                                            </StyledBox7>
                                        )}
                                    </StyledBox7>
                                    <StyledBox10 style={{
                                        display: "flex", gap: "35px",
                                        paddingRight: "20px", alignItems: "center",
                                    }}>
                                        <StyledBox9 style={{
                                            display: "flex", gap: "35px", alignItems: "center"
                                        }}>
                                            <Box style={{
                                                display: "flex", flexDirection: "column",
                                                gap: "2px", alignItems: "center"
                                            }}
                                                data-test-id="messageNavigationTestBtn2"
                                                onClick={() => this.handleMessageNavigation()}
                                            >
                                                <PopupIconButton style={{
                                                    height: "60px", width: "60px", borderRadius: "10px",
                                                    padding: "12px 24px", background: "#F7F8FA"
                                                }}>
                                                    <MailRoundedIcon style={{ color: "#11141A" }} />
                                                </PopupIconButton>
                                                <StyledTypo8 style={{
                                                    width: "60px", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                                    fontSize: "12px", fontWeight: 400, textAlign: "center"
                                                }}
                                                    sx={{
                                                        "@media (max-width: 500px)": {
                                                            width: "41px !important",
                                                            height: "15px !important"
                                                        },
                                                    }}
                                                >
                                                    Message
                                                </StyledTypo8>
                                            </Box>
                                            {this.rescheduleIcon2()}
                                            {this.cancelIcon2()}
                                            {this.declineIcon2()}
                                        </StyledBox9>
                                        <Box style={{ display: "flex" }}>
                                            {this.acceptIcon1()}
                                        </Box>
                                    </StyledBox10>
                                </StyledBox6>
                            ))}
                            {this.state.userType === "Hire A Teen" && (
                                <ButtonBookingSeeMore style={{
                                    display: "flex", width: "109px", height: "30px",
                                    borderRadius: "10px", border: "1px solid #5E70DD",
                                    color: "#5E70DD", fontFamily: "Manrope", fontWeight: 600,
                                    fontSize: "12px", textTransform: "none", alignSelf: "flex-start"
                                }}
                                    startIcon={<RemoveRedEyeIcon />}
                                    data-test-id="seeMoreTestBtn"
                                    onClick={() => this.handleSeeMore()}
                                >
                                    {this.handleSeeMoreText()}
                                </ButtonBookingSeeMore>
                            )}
                        </Box>
                    )}
                    {this.state.openAllBookingsOrChooseAvailability == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                fontFamily: "Manrope", fontWeight: 600,
                                fontSize: "24px", color: "#101928",
                                width: "fit-content", height: "33px",
                            }}>
                                Past
                            </Typography>
                            {this.state.pastData?.map((item: BookingData, keyData: number) => (
                                <StyledBox6 style={{
                                    display: "flex", borderRadius: "8px",
                                    borderRight: "2px solid #5E70DD", justifyContent: "space-between",
                                    height: "auto", width: "100%",
                                }}>
                                    <StyledBox7 style={{ display: "flex", gap: "20px" }}>
                                        <Box style={{
                                            display: "flex", padding: "12px",
                                            gap: "12px", width: "fit-content"
                                        }}>
                                            <img
                                                alt="profile_pic"
                                                style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                                src={item.attributes.photo}
                                            />
                                            <Box style={{
                                                flexDirection: "column", display: "flex",
                                                justifyContent: "center",
                                            }}>
                                                <Typography style={{
                                                    fontFamily: "Manrope", color: "#11141A",
                                                    fontSize: "10px", fontWeight: 400,
                                                    width: "fit-content",
                                                }}>
                                                    {item.attributes.start_date}
                                                </Typography>
                                                <Typography style={{
                                                    fontSize: "16px", fontFamily: "Manrope",
                                                    width: "fit-content",
                                                    color: "#11141A", fontWeight: 700,
                                                }}>
                                                    {item.attributes.sender_full_name}
                                                </Typography>
                                                <Typography style={{
                                                    fontFamily: "Manrope", color: "#11141A",
                                                    width: "fit-content",
                                                    fontSize: "14px", fontWeight: 400,
                                                }}>
                                                    {item.attributes.service_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {this.state.userType != "Hire A Teen" && (
                                            <Box style={{
                                                display: "flex", justifyContent: "center", alignItems: "start",
                                                flexDirection: "column", gap: "6px",
                                            }}>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                    <StyledTypo5 style={{
                                                        color: "#161C2D", fontWeight: 400,
                                                        width: "fit-content", fontFamily: "Manrope",
                                                        fontSize: "14px",
                                                    }}>
                                                        {item.attributes.address}
                                                    </StyledTypo5>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <TimerImage
                                                        style={{ height: "15px", width: "15px" }}
                                                        src={timer.default}
                                                    />
                                                    <StyledTypo7 style={{
                                                        fontSize: "15px", height: "21px", width: "fit-content",
                                                        color: "#161C2D", fontWeight: 400,
                                                    }}>
                                                        {item.attributes.frequency}
                                                    </StyledTypo7>
                                                </Box>
                                            </Box>
                                        )}
                                    </StyledBox7>
                                    {this.state.userType == "Hire A Teen" && (
                                        <Box style={{
                                            display: "flex", alignItems: "center",
                                            gap: "20px", paddingRight: "20px",
                                        }}>
                                            <Box style={{
                                                display: "flex", flexDirection: "column",
                                                gap: "4px", alignItems: "center"
                                            }}>
                                                <PopupIconButton style={{
                                                    height: "60px", width: "60px", borderRadius: "10px",
                                                    padding: "12px 24px", background: "#F7F8FA"
                                                }}
                                                    data-test-id="receiptPopupTestBtn"
                                                    onClick={() => this.handleReceiptPopup()}
                                                >
                                                    <img src={receipt.default}
                                                        style={{ color: "#0F172A" }}
                                                    />
                                                </PopupIconButton>
                                                <StyledTypo8 style={{
                                                    width: "67px", height: "21px", color: "#11141A", fontFamily: "Manrope",
                                                    fontSize: "12px", fontWeight: 400, textAlign: "center"
                                                }}
                                                    sx={{
                                                        "@media (max-width: 500px)": {
                                                            minWidth: "48px !important",
                                                            height: "15px !important"
                                                        },
                                                    }}
                                                >
                                                    See Receipt
                                                </StyledTypo8>
                                            </Box>
                                            <Box style={{
                                                gap: "4px", alignItems: "center",
                                                display: "flex", flexDirection: "column",
                                            }}>
                                                <PopupIconButton style={{
                                                    padding: "12px 24px", background: "#F7F8FA",
                                                    height: "60px", width: "60px", borderRadius: "10px",
                                                }}>
                                                    <SearchIcon style={{ color: "#0F172A" }} />
                                                </PopupIconButton>
                                                <StyledTypo8 style={{
                                                    fontSize: "12px", fontWeight: 400, textAlign: "center",
                                                    width: "109px", height: "21px", minWidth: "109px",
                                                    color: "#11141A", fontFamily: "Manrope",
                                                }}
                                                    sx={{
                                                        "@media (max-width: 500px)": {
                                                            minWidth: "78px !important",
                                                            height: "15px !important"
                                                        },
                                                    }}
                                                >
                                                    Rebook this Service
                                                </StyledTypo8>
                                            </Box>
                                        </Box>
                                    )}
                                </StyledBox6>
                            ))}
                        </Box>
                    )}
                    {this.state.openAllBookingsOrChooseAvailability == "All Bookings" && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <Typography style={{
                                width: "fit-content", height: "33px", fontFamily: "Manrope",
                                fontSize: "24px", fontWeight: 600, color: "#101928"
                            }}>
                                Canceled
                            </Typography>
                            {this.state.canceledData?.map((item: BookingData, keyData: number) => (
                                <StyledBox6 style={{
                                    borderRight: "2px solid #5E70DD", height: "106px",
                                    justifyContent: "space-between",
                                    width: "100%", borderRadius: "8px", display: "flex",
                                }}>
                                    <StyledBox7 style={{ display: "flex", gap: "20px" }}>
                                        <Box style={{
                                            display: "flex", padding: "12px",
                                            gap: "12px", width: "fit-content"
                                        }}>
                                            <img
                                                style={{ width: "78px", height: "71px", borderRadius: "8px" }}
                                                alt="profile_pic"
                                                src={item.attributes.photo}
                                            />
                                            <Box style={{
                                                justifyContent: "center",
                                                flexDirection: "column", display: "flex",
                                            }}>
                                                <Typography style={{
                                                    fontFamily: "Manrope", width: "fit-content",
                                                    fontSize: "10px", fontWeight: 400,
                                                    color: "#11141A",
                                                }}>
                                                    {item.attributes.start_date}
                                                </Typography>
                                                <Typography style={{
                                                    fontSize: "16px", width: "fit-content",
                                                    fontFamily: "Manrope",
                                                    color: "#11141A", fontWeight: 700,
                                                }}>
                                                    {item.attributes.sender_full_name}
                                                </Typography>
                                                <Typography style={{
                                                    fontWeight: 400,
                                                    fontFamily: "Manrope", width: "fit-content",
                                                    fontSize: "14px", color: "#11141A"
                                                }}>
                                                    {item.attributes.service_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {this.state.userType != "Hire A Teen" && (
                                            <Box style={{
                                                display: "flex", justifyContent: "center",
                                                flexDirection: "column", gap: "6px", alignItems: "start"
                                            }}>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <LocationOnIcon style={{ height: "15px", width: "15px" }} />
                                                    <StyledTypo5 style={{
                                                        width: "fit-content", fontFamily: "Manrope",
                                                        color: "#161C2D", fontWeight: 400,
                                                        fontSize: "14px",
                                                    }}>
                                                        {item.attributes.address}
                                                    </StyledTypo5>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <TimerImage style={{ height: "15px", width: "15px" }}
                                                        src={timer.default}
                                                    />
                                                    <StyledTypo7 style={{
                                                        fontSize: "15px", width: "fit-content",
                                                        color: "#161C2D", fontWeight: 400,
                                                        height: "21px",
                                                    }}>
                                                        {item.attributes.frequency}
                                                    </StyledTypo7>
                                                </Box>
                                            </Box>
                                        )}
                                    </StyledBox7>
                                    {this.state.userType == "Hire A Teen" && (
                                        <Box style={{
                                            gap: "25px", paddingRight: "20px",
                                            display: "flex", alignItems: "center",
                                        }}>
                                            <Box style={{
                                                display: "flex", flexDirection: "column",
                                                gap: "4px", alignItems: "center"
                                            }}>
                                                <PopupIconButton style={{
                                                    height: "60px", width: "60px", borderRadius: "10px",
                                                    padding: "12px 24px", background: "#F7F8FA"
                                                }}>
                                                    <SearchIcon style={{ color: "#0F172A" }} />
                                                </PopupIconButton>
                                                <StyledTypo8 style={{
                                                    width: "100%", height: "21px", minWidth: "109px",
                                                    color: "#11141A", fontFamily: "Manrope",
                                                    fontSize: "12px", fontWeight: 400, textAlign: "center"
                                                }}
                                                    sx={{
                                                        "@media (max-width: 500px)": {
                                                            minWidth: "78px !important",
                                                            height: "15px !important"
                                                        },
                                                    }}
                                                >
                                                    Rebook this Service
                                                </StyledTypo8>
                                            </Box>
                                        </Box>
                                    )}
                                </StyledBox6>
                            ))}
                        </Box>
                    )}
                    {this.renderAvailability()}
                </Box>
                <Modal
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.openCancelPopup}
                >
                    <StyledBox11 style={{
                        width: "600px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "25px",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px"
                        }}
                            data-test-id="closeCancelIconTestBtn"
                            onClick={() => this.closeCancelPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            color: "#0F172A", fontSize: "24px", fontFamily: "Manrope",
                            fontWeight: 700, padding: "0px 30px",
                        }}>
                            Are you sure you want to cancel your Booking?
                        </Typography>
                        <Box style={{ display: "flex", gap: "12px", padding: "0px 30px", }}>
                            <img
                                alt="profile_pic"
                                style={{
                                    height: "71px",
                                    width: "78px", borderRadius: "8px"
                                }}
                                src={bookingProfilePic.default}
                            />
                            <Box style={{
                                flexDirection: "column",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Typography style={{
                                    height: "21px", fontFamily: "Manrope",
                                    fontSize: "10px", fontWeight: 400,
                                    color: "#11141A", width: "fit-content",
                                }}>
                                    Sun 16 July 2024 at 9:00pm
                                </Typography>
                                <Typography style={{
                                    fontSize: "16px", fontWeight: 700,
                                    width: "fit-content", height: "22px",
                                    color: "#11141A", fontFamily: "Manrope",
                                }}>
                                    Jack Snow
                                </Typography>
                                <Typography style={{
                                    fontSize: "14px", fontWeight: 400,
                                    width: "fit-content", height: "21px",
                                    fontFamily: "Manrope",
                                    color: "#11141A",
                                }}>
                                    Tech Help
                                </Typography>
                            </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Button variant="contained"
                            style={{
                                background: "#5E70DD", color: "#FFFFFF",
                                fontSize: "16px", fontWeight: 700,
                                width: "fit-content", height: "43px",
                                fontFamily: "Manrope", borderRadius: "10px",
                                textTransform: "none", alignSelf: "center"
                            }}
                        > Cancel Booking
                        </Button>
                    </StyledBox11>
                </Modal>
                <Modal
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.openReceiptPopup}
                >
                    <Box style={{
                        width: "908px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "5px",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px"
                        }}
                            data-test-id="closeReceiptIconTestBtn"
                            onClick={() => this.closeReceiptPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            color: "#0F172A", fontSize: "24px",
                            fontWeight: 700, padding: "0px 30px",
                            fontFamily: "Manrope",
                        }}>
                            Booking Receipt
                        </Typography>
                        <Box style={{ display: "flex", gap: "40px" }}>
                            <Box style={{
                                display: "flex", gap: "12px",
                                padding: "10px 30px", height: "fit-content"
                            }}>
                                <img
                                    alt="profile_pic"
                                    style={{
                                        height: "71px",
                                        width: "78px", borderRadius: "8px"
                                    }}
                                    src={bookingProfilePic.default}
                                />
                                <Box style={{
                                    flexDirection: "column",
                                    display: "flex", justifyContent: "center"
                                }}>
                                    <Typography style={{
                                        fontSize: "16px", fontWeight: 700,
                                        color: "#11141A", fontFamily: "Manrope",
                                        width: "fit-content", height: "22px",
                                    }}>
                                        Jack Sparrow
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        color: "#11141A",
                                        width: "fit-content", height: "21px",
                                        fontFamily: "Manrope",
                                    }}>
                                        Intergenerational Activities
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{
                                flexDirection: "column", width: "310px",
                                justifyContent: "center", padding: "0px 30px",
                                display: "flex", gap: "10px"
                            }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: "center", gap: "16px"
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#707070",
                                        fontSize: "14px", fontWeight: 400,
                                    }}>
                                        Booking ID
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#121212",
                                        fontSize: "14px", fontWeight: 500,
                                    }}>
                                        0000123456789
                                    </Typography>
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    alignItems: "center", gap: "16px",
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        fontFamily: "Manrope", color: "#707070",
                                    }}>
                                        Date
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 500,
                                        fontFamily: "Manrope", color: "#121212",
                                    }}>
                                        Mar 22, 2023
                                    </Typography>
                                </Box>
                                <Box style={{
                                    alignItems: "center", gap: "16px",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope",
                                        fontSize: "14px", color: "#707070",
                                        fontWeight: 400,
                                    }}>
                                        Time
                                    </Typography>
                                    <Typography style={{
                                        color: "#121212",
                                        fontFamily: "Manrope",
                                        fontSize: "14px", fontWeight: 500,
                                    }}>
                                        07:80 AM
                                    </Typography>
                                </Box>
                                <Box style={{
                                    justifyContent: "space-between",
                                    display: 'flex',
                                    alignItems: "center", gap: "16px"
                                }}>
                                    <Typography style={{
                                        fontSize: "14px", fontWeight: 400,
                                        fontFamily: "Manrope",
                                        color: "#707070",
                                    }}>
                                        Payment Method
                                    </Typography>
                                    <Typography style={{
                                        fontSize: "14px",
                                        fontFamily: "Manrope", color: "#121212",
                                        fontWeight: 500,
                                    }}>
                                        Credit Card
                                    </Typography>
                                </Box>
                                <Box style={{
                                    alignItems: "center", gap: "16px",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                }}>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#707070",
                                        fontSize: "14px", fontWeight: 600,
                                    }}>
                                        Amount
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: "Manrope", color: "#121212",
                                        fontSize: "16px", fontWeight: 500,
                                    }}>
                                        $50,00
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider
                            style={{ marginTop: "15px" }}
                            orientation="horizontal" flexItem
                        />
                    </Box>
                </Modal>
                <StyledRecheduleModal sx={{
                    overflow: "auto", display: 'flex',
                    alignItems: 'center', paddingTop: "260px",
                    justifyContent: 'center', paddingBottom: "30px"
                }}
                    open={this.state.openReschedulePopup}
                >
                    <StyledCardReschedule style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                        width: "850px", height: "650px", background: "#F1F5F9",
                        display: "flex", flexDirection: "column",
                        padding: "0px 60px 0px 30px",
                    }}>
                        <StyledIconButton1 style={{ alignSelf: "flex-end", left: "50px" }}
                            onClick={() => this.closeReschedulePopup()}
                            data-test-id="closeRescheduleIconTestBtn"
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </StyledIconButton1>
                        <StyledBox18 style={{ display: "flex", gap: "40px", alignItems: "center" }}>
                            <UserImageInReschedule
                                src={bookingProfilePic.default}
                                alt="profile_pic"
                                style={{
                                    borderRadius: "4px",
                                    height: "176px", width: "148px",
                                }}
                            />
                            <Box style={{
                                display: 'flex', flexDirection: "column",
                                gap: "6px"
                            }}>
                                <StyledTypo12 style={{
                                    width: "fit-content",
                                    fontFamily: "Manrope", color: "#141414",
                                    fontSize: "24px", fontWeight: 600,
                                }}>
                                    Jack Tohas
                                </StyledTypo12>
                                <StyledBox19 style={{
                                    display: "flex", gap: "25px",
                                    width: "fit-content", flexWrap: "wrap"
                                }}>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <img src={location.default} />
                                        <StyledTypo13 style={{
                                            fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}>  New York
                                            <span style={{
                                                marginLeft: "20px",
                                                color: "rgba(20, 20, 20, 0.7)"
                                            }}>.
                                            </span>
                                        </StyledTypo13>
                                    </Box>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <StyledTypo13 style={{
                                            fontFamily: "Manrope", fontWeight: 600, width: "fit-content",
                                            fontSize: "16px", color: "#141414"
                                        }}> State:
                                        </StyledTypo13>
                                        <StyledTypo13 style={{
                                            fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}> New York
                                        </StyledTypo13>
                                    </Box>
                                    <Box style={{
                                        display: "flex", gap: "10px",
                                        alignItems: "center"
                                    }}>
                                        <StyledTypo13 style={{
                                            fontFamily: "Manrope", fontWeight: 600, width: "fit-content",
                                            fontSize: "16px", color: "#141414"
                                        }}> ZIP Code:
                                        </StyledTypo13>
                                        <StyledTypo13 style={{
                                            fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                            fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                        }}> 10956
                                        </StyledTypo13>
                                    </Box>
                                </StyledBox19>
                                <StyledTypo13 style={{
                                    fontFamily: "DM Sans", fontWeight: 400, width: "fit-content",
                                    fontSize: "16px", color: "rgba(20, 20, 20, 0.7)"
                                }}> {`$ ${120}/h`}
                                </StyledTypo13>
                                <StyledTypo13 style={{
                                    height: "24px", width: "fit-content",
                                    fontFamily: "Manrope", color: "#141414",
                                    fontSize: "16px", fontWeight: 700,
                                }}>
                                    Services
                                </StyledTypo13>
                                <StyledSelectField2
                                    data-test-id="calPopupDropdownTestBtn"
                                    defaultValue={"Intergenerational Activities"}
                                    style={{
                                        width: "208px", height: "30px", borderRadius: "10px",
                                        fontSize: "12px", color: "#393939", outline: "none",
                                        border: "none", fontFamily: "Manrope", fontWeight: 700,
                                        cursor: "pointer", background: "#FFFFFF",
                                        justifyContent: "center", padding: "5px 8.5px",
                                    }}
                                    variant="standard"
                                    disableUnderline
                                >
                                    <MenuItem value={"Intergenerational Activities"} >
                                        {"Intergenerational Activities"}
                                    </MenuItem>
                                </StyledSelectField2>
                            </Box>
                        </StyledBox18>
                        <StyledBox16 style={{ display: "flex", marginTop: "20px", justifyContent: "space-between" }}>
                            <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterDayjs}>
                                <DatePicker open={this.state.openCalender}
                                    data-test-id="calenderPopupTestBtn"
                                    onChange={(event) => this.handleDatePicker(event)}
                                    minDate={dayjs()}
                                    showDaysOutsideCurrentMonth
                                    slots={{ toolbar: this.CustomToolbarCal }}
                                    slotProps={{
                                        day: {
                                            sx: {
                                                '&.MuiPickersDay-root.Mui-selected': {
                                                    border: "1px solid #E1CCFF",
                                                    backgroundColor: '#F0E5FF',
                                                    color: "#6200EA",
                                                    fontWeight: 700
                                                },
                                                "&.MuiPickersDay-today": {
                                                    border: "none",
                                                    fontWeight: 700, color: "#0F172A",
                                                },
                                                "&.MuiPickersDay-root": {
                                                    fontWeight: 400, fontFamily: "Inter"
                                                }
                                            }
                                        },
                                        popper: {
                                            sx: {
                                                ".MuiPaper-root": {
                                                    position: "absolute",
                                                    top: "-60px",
                                                    boxShadow: "none",
                                                    border: "1px solid #CBD5E1",
                                                    width: "374px",
                                                    borderRadius: "8px",
                                                    "@media (max-width: 500px)": {
                                                        width: "310px",
                                                    },
                                                },
                                            },
                                        },
                                        layout: {
                                            sx: {
                                                "&.MuiPickersLayout-root": {
                                                    display: "flex", flexDirection: "column"
                                                }
                                            }
                                        },
                                        nextIconButton: {
                                            sx: {
                                                left: "190px"
                                            }
                                        },
                                        calendarHeader: {
                                            sx: {
                                                ".MuiPickersCalendarHeader-label": {
                                                    color: '#0F172A', fontWeight: 700,
                                                    fontSize: '14px',
                                                    fontFamily: 'Inter',
                                                },
                                                flexDirection: "row-reverse",
                                            }
                                        },
                                        switchViewButton: {
                                            sx: {
                                                display: 'none'
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <StyledBox17 style={{
                                display: "flex", alignItems: "center",
                                flexDirection: "column", gap: "15px",
                                position: "relative", bottom: "90px"
                            }}>
                                <StyledBox20 style={{
                                    width: "360px", border: "1px solid #CBD5E1",
                                    borderRadius: '10px', height: "126px", background: "#FFFFFF",
                                    display: "flex", gap: "15px", flexDirection: "column"
                                }}>
                                    <Typography style={{
                                        width: "fit-content", margin: "10px 0px 0px 20px",
                                        fontFamily: "Manrope", fontSize: '20px',
                                        fontWeight: 600, color: "#272727",
                                    }}>
                                        Frequency
                                    </Typography>
                                    <Select
                                        defaultValue={1}
                                        style={{
                                            width: "240px", paddingLeft: "0px", color: "#161C2D",
                                            margin: "0px 0px 0px 20px", gap: "10px", height: "50px",
                                            fontFamily: "Manrope", fontWeight: 400, fontSize: "15px"
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <RefreshIcon style={{
                                                    color: "#0F172A",
                                                    position: "relative", left: "12px"
                                                }} />
                                            </InputAdornment>
                                        }
                                    >
                                        <MenuItem key={1} value={1} >Once</MenuItem>
                                    </Select>
                                </StyledBox20>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <StyledBox20 style={{
                                        width: "360px", border: "1px solid #CBD5E1",
                                        borderRadius: '10px', height: "126px", background: "#FFFFFF",
                                        display: "flex", gap: "15px", flexDirection: "column"
                                    }}>
                                        <Typography style={{
                                            width: "fit-content", margin: "10px 0px 0px 20px",
                                            fontFamily: "Manrope", fontSize: '20px',
                                            fontWeight: 600, color: "#272727",
                                        }}>
                                            Start Time
                                        </Typography>
                                        <TimePicker
                                            defaultValue={dayjs('2022-04-17T15:30')}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        ".MuiOutlinedInput-root": {
                                                            display: "flex", height: "50px",
                                                            flexDirection: "row-reverse",
                                                            width: "240px", paddingLeft: "0px",
                                                            margin: "0px 0px 0px 20px", gap: "10px",
                                                        },
                                                        ".MuiSvgIcon-root": {
                                                            color: "#0F172A"
                                                        }
                                                    },
                                                    InputProps: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <ArrowDropDownIcon style={{ color: "#0F172A" }} />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                },
                                            }}
                                        />
                                    </StyledBox20>
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <StyledBox20 style={{
                                        width: "360px", border: "1px solid #CBD5E1",
                                        borderRadius: '10px', height: "126px", background: "#FFFFFF",
                                        display: "flex", gap: "15px", flexDirection: "column"
                                    }}>
                                        <Typography style={{
                                            width: "fit-content", margin: "10px 0px 0px 20px",
                                            fontFamily: "Manrope", fontSize: '20px',
                                            fontWeight: 600, color: "#272727"
                                        }}>
                                            End Time
                                        </Typography>
                                        <TimePicker
                                            defaultValue={dayjs('2022-04-17T15:30')}
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        ".MuiOutlinedInput-root": {
                                                            display: "flex", height: "50px",
                                                            flexDirection: "row-reverse",
                                                            width: "240px", paddingLeft: "0px",
                                                            margin: "0px 0px 0px 20px", gap: "10px",
                                                        },
                                                        ".MuiSvgIcon-root": {
                                                            color: "#0F172A"
                                                        }
                                                    },
                                                    InputProps: {
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <ArrowDropDownIcon style={{ color: "#0F172A" }} />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }
                                            }}
                                        />
                                    </StyledBox20>
                                </LocalizationProvider>
                                <StyledButton1 style={{
                                    background: "#5E70DD", color: "#FFFFFF",
                                    textTransform: "none", height: "43px", width: "344px",
                                    borderRadius: "10px", padding: "12px 24px",
                                    fontFamily: "Roboto", fontSize: '16px',
                                    fontWeight: 700,
                                }}>
                                    Reschedule This Teen
                                </StyledButton1>
                            </StyledBox17>
                        </StyledBox16>
                    </StyledCardReschedule>
                </StyledRecheduleModal>
                <Modal
                    sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                    open={this.state.openAcceptPopup}
                >
                    <StyledCard12 style={{
                        display: "flex", flexDirection: "column", gap: "25px",
                        width: "600px", height: "350px", background: "#FFFFFF",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px",
                    }}>
                        <IconButton style={{
                            padding: "20px 20px 0px 0px",
                            alignSelf: "flex-end",
                        }}
                            data-test-id="closeAcceptIconTestBtn"
                            onClick={() => this.closeAcceptPopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            fontWeight: 700, padding: "0px 30px", fontFamily: "Manrope",
                            color: "#0F172A", fontSize: "24px",
                        }}>
                            Are you sure you want to Accept your Booking?
                        </Typography>
                        <Box style={{ display: "flex", gap: "12px", padding: "0px 30px", }}>
                            <img
                                alt="profile_pic"
                                style={{
                                    height: "71px",
                                    width: "78px", borderRadius: "8px"
                                }}
                                src={bookingProfilePic.default}
                            />
                            <Box style={{
                                flexDirection: "column", display: "flex",
                                justifyContent: "center"
                            }}>
                                <Typography style={{
                                    height: "21px", fontFamily: "Manrope",
                                    color: "#11141A", width: "fit-content",
                                    fontSize: "10px", fontWeight: 400,
                                }}>
                                    Sun 14 July 2020 at 9:00pm
                                </Typography>
                                <Typography style={{
                                    width: "fit-content", height: "22px",
                                    fontSize: "16px", fontWeight: 700,
                                    color: "#11141A", fontFamily: "Manrope",
                                }}>
                                    Jack Peek
                                </Typography>
                                <Typography style={{
                                    color: "#11141A",
                                    width: "fit-content", height: "21px",
                                    fontFamily: "Manrope",
                                    fontSize: "14px", fontWeight: 400,
                                }}>
                                    Tech Help
                                </Typography>
                            </Box>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Box style={{
                            display: "flex", alignSelf: "flex-end",
                            gap: "20px", marginRight: "20px"
                        }}>
                            <Button variant="contained"
                                style={{
                                    background: "#FFFFFF", color: "#5E70DD",
                                    textTransform: "none", border: "1px solid #5E70DD",
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    width: "143px", height: "43px",
                                    fontSize: "16px", fontWeight: 700,
                                }}
                            > Cancel
                            </Button>
                            <Button variant="contained"
                                style={{
                                    background: "#5E70DD", color: "#FFFFFF",
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    fontSize: "16px", fontWeight: 700,
                                    textTransform: "none",
                                    width: "143px", height: "43px",
                                }}
                            > Accept
                            </Button>
                        </Box>
                    </StyledCard12>
                </Modal>
                <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={this.state.openDeclinePopup}
                >
                    <StyledCard12 style={{
                        width: "600px", height: "350px", borderRadius: "10px",
                        display: "flex", flexDirection: "column", gap: "25px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", background: "#FFFFFF",
                    }}>
                        <IconButton style={{
                            alignSelf: "flex-end",
                            padding: "20px 20px 0px 0px",
                        }}
                            data-test-id="closeDeclineIconTestBtn"
                            onClick={() => this.closeDeclinePopup()}
                        >
                            <CloseIcon style={{ color: "#334155" }} />
                        </IconButton>
                        <Typography style={{
                            fontWeight: 700, fontFamily: "Manrope", color: "#0F172A",
                            fontSize: "24px", padding: "0px 30px",
                        }}>
                            Why are you declining?
                        </Typography>
                        <Box style={{ padding: "0px 30px" }}>
                            <TextField
                                variant="standard"
                                data-test-id="declineTextTestBtn"
                                type="text"
                                value={this.state.declineText}
                                style={{
                                    width: "90%", height: "80px", borderRadius: "8px",
                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                    padding: "12px 16px", outline: "none",
                                    fontSize: "16px", textAlign: "center",
                                }}
                                sx={{
                                    input: {
                                        color: '#A8ADB7', fontWeight: 400,
                                        fontSize: "16px", fontFamily: "Manrope"
                                    },
                                }}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event) => this.changeDeclineText(event)}
                                placeholder="Enter text"
                            >
                            </TextField>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        <Box style={{
                            display: "flex", alignSelf: "flex-end",
                            gap: "20px", marginRight: "20px"
                        }}>
                            <Button variant="contained"
                                style={{
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    fontSize: "16px", fontWeight: 700, background: "#FFFFFF",
                                    color: "#5E70DD", border: "1px solid #5E70DD",
                                    textTransform: "none",
                                    width: "143px", height: "43px",
                                }}
                            > Cancel
                            </Button>
                            <Button variant="contained"
                                style={{
                                    color: "#FFFFFF", width: "143px", height: "43px",
                                    fontSize: "16px", fontWeight: 700,
                                    fontFamily: "Manrope", borderRadius: "10px",
                                    textTransform: "none", background: "#5E70DD"
                                }}
                            > Decline
                            </Button>
                        </Box>
                    </StyledCard12>
                </Modal>
            </>
        )
    }

    CustomToolbarCal = () => (
        <StyledBox21 style={{ display: "flex", alignItems: "center", padding: "10px 0px 0px 60px" }}>
            <StyledTypo14 style={{
                width: "fit-content", fontFamily: "Manrope",
                fontWeight: 700, fontSize: "20px", color: "#272727"
            }}>
                Confirm Start Date
            </StyledTypo14>
        </StyledBox21>
    );

    rescheduleIcon1() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{
                    display: "flex",
                    gap: "2px", flexDirection: "column",
                }}>
                    <PopupIconButton style={{
                        background: "#F7F8FA", borderRadius: "10px",
                        height: "60px", width: "60px", padding: "12px 24px",
                    }}
                        data-test-id="reschedulePopupTestBtn"
                        onClick={() => this.handleReschedulePopup()}
                    >
                        <img
                            alt="reschedule"
                            src={rescheduleLogo.default}
                            style={{ height: "21px", width: "21px" }}
                        />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        width: "66px", height: "21px",
                        color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400, textAlign: "center"
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                width: "46px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Reschedule
                    </StyledTypo8>
                </Box>
            )
        )
    }

    rescheduleIcon2() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <PopupIconButton style={{
                        height: "60px", width: "60px", borderRadius: "10px",
                        padding: "12px 24px", background: "#F7F8FA"
                    }}
                        data-test-id="reschedulePopupSecondTestBtn"
                        onClick={() => this.handleReschedulePopup()}
                    >
                        <img
                            src={rescheduleLogo.default}
                            alt="reschedule"
                            style={{ height: "21px", width: "21px" }}
                        />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        width: "66px", height: "21px", color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400, textAlign: "center"
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                width: "46px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Reschedule
                    </StyledTypo8>
                </Box>
            )
        )
    }

    acceptIcon1() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{
                    display: "flex", flexDirection: "column",
                    gap: "2px", alignItems: "center"
                }}>
                    <PopupIconButton style={{
                        padding: "12px 24px", background: "#F7F8FA",
                        height: "60px", width: "60px", borderRadius: "10px",
                    }}
                        data-test-id="acceptPopupTestBtn"
                        onClick={() => this.handleAcceptPopup()}
                    >
                        <CheckCircleIcon style={{ color: "#000000" }} />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        width: "40px", height: "21px", textAlign: "center",
                        fontSize: "12px", fontWeight: 400,
                        color: "#11141A", fontFamily: "Manrope",
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                minWidth: "27px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Accept
                    </StyledTypo8>
                </Box>
            )
        )
    }

    cancelIcon1() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{
                    display: "flex", flexDirection: "column",
                    gap: "2px", alignItems: "center"
                }}>
                    <PopupIconButton style={{
                        height: "60px", width: "60px",
                        borderRadius: "10px",
                        padding: "12px 24px", background: "#F7F8FA",
                    }}
                        data-test-id="cancelPopupTestBtn"
                        onClick={() => this.handleCancelPopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        fontSize: "12px", fontWeight: 400,
                        width: "39px", height: "21px", textAlign: "center",
                        color: "#11141A", fontFamily: "Manrope",
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                width: "27px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Cancel
                    </StyledTypo8>
                </Box>
            )
        )
    }

    cancelIcon2() {
        return (
            this.state.userType == "Hire A Teen" && (
                <Box style={{
                    flexDirection: "column", gap: "2px",
                    display: "flex", alignItems: "center"
                }}>
                    <PopupIconButton style={{
                        height: "60px", width: "60px", background: "#F7F8FA",
                        borderRadius: "10px", padding: "12px 24px",
                    }}
                        data-test-id="cancelPopupSecondTestBtn"
                        onClick={() => this.handleCancelPopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        color: "#11141A", fontFamily: "Manrope",
                        fontSize: "12px", fontWeight: 400,
                        width: "39px", height: "21px", textAlign: "center"
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                width: "27px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Cancel
                    </StyledTypo8>
                </Box>
            )
        )
    }

    declineIcon1() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{
                    display: "flex", flexDirection: "column",
                    gap: "2px", alignItems: "center"
                }}>
                    <PopupIconButton style={{
                        padding: "12px 24px", width: "60px",
                        height: "60px", background: "#F7F8FA",
                        borderRadius: "10px",
                    }}
                        data-test-id="declinePopupTestBtn1"
                        onClick={() => this.handleDeclinePopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        width: "42px", height: "21px", textAlign: "center",
                        fontSize: "12px", fontWeight: 400,
                        color: "#11141A", fontFamily: "Manrope",
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                minWidth: "29px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Decline
                    </StyledTypo8>
                </Box>
            )
        )
    }

    declineIcon2() {
        return (
            this.state.userType != "Hire A Teen" && (
                <Box style={{
                    display: "flex", flexDirection: "column",
                    gap: "2px", alignItems: "center"
                }}>
                    <PopupIconButton style={{
                        padding: "12px 24px", width: "60px", borderRadius: "10px",
                        height: "60px", background: "#F7F8FA",
                    }}
                        data-test-id="declinePopupTestBtn2"
                        onClick={() => this.handleDeclinePopup()}
                    >
                        <CancelIcon style={{ color: "#11141A" }} />
                    </PopupIconButton>
                    <StyledTypo8 style={{
                        fontSize: "12px", fontWeight: 400,
                        width: "42px", height: "21px", textAlign: "center",
                        color: "#11141A", fontFamily: "Manrope",
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                minWidth: "29px !important",
                                height: "15px !important"
                            },
                        }}
                    >
                        Decline
                    </StyledTypo8>
                </Box>
            )
        )
    }

    renderMessages() {
        return (
            <StyledBox22 style={{ display: "flex", flexDirection: "column", }}>
                <StyledTypo15 style={{
                    fontWeight: 700, fontFamily: "Manrope", color: "#25324B",
                    fontSize: "16px", cursor: "pointer"
                }}
                    data-test-id="loadAllChatsTestBtn"
                    onClick={() => this.loadAllChats()}
                >
                    All Chats
                </StyledTypo15>
                <StyledBox23 style={{ display: 'flex', gap: "25px" }}>
                    <StyledBox24
                        style={{ display: "flex", height: "100vh", flexGrow: 1 }}
                        ref={this.hideChatSummary}
                    >
                        <StyledBox25 style={{
                            display: "flex", margin: "20px 0px",
                            flexDirection: "column", overflowY: "auto", gap: "30px"
                        }}>
                            <this.ChatSummary />
                            <this.ChatSummary />
                            <this.ChatSummary />
                        </StyledBox25>
                    </StyledBox24>
                    <Box style={{
                        display: "flex", flexGrow: 1.5, height: "100vh",
                        flexDirection: "column",
                    }}
                        sx={{
                            "@media (max-width: 500px)": {
                                display: `${this.handleShowHideChats()}`
                            },
                        }}
                        ref={this.showChatMessages}
                    >
                        <AppBar position="static"
                            sx={{
                                padding: "10px", backgroundColor: "#FFFFFF",
                                boxShadow: "none", borderBottom: "1px solid #DFDFDF"
                            }}
                        >
                            <Grid container alignItems="center">
                                <StyledAvatar2 alt="User profile"
                                    style={{ width: "49px", height: "48px" }}
                                    src={bookingProfilePic.default}
                                />
                                <Box sx={{ marginLeft: "10px" }}>
                                    <StyledTypo16 style={{
                                        fontWeight: 600, fontFamily: "Manrope",
                                        fontSize: "20px", color: "#515151",
                                    }}> Benjamin Knight
                                    </StyledTypo16>
                                </Box>
                            </Grid>
                        </AppBar>
                        <StyledBox28 sx={{
                            display: 'flex', flexDirection: "column", gap: "40px",
                            overflowY: "auto", flexGrow: 1, padding: "20px",
                            backgroundColor: "#D6DDEB",
                        }}>
                            <this.ChatMessage message="Hi Jack! I’m doing well, thanks. Can’t wait for the weekend!" time="10:30 AM" alignRight={false} />
                            <this.ChatMessage message="I know, right? Weekend plans are the best. Any exciting plans on your end?" time="10:35 AM" alignRight={true} />
                            <this.ChatMessage message="Absolutely! I’m thinking of going for a hike on Saturday. How about you?" time="10:40 AM" alignRight={false} />
                            <this.ChatMessage message="Hiking sounds amazing! I might catch up on some reading and also meet up with a few friends on Sunday." time="10:50 AM" alignRight={true} />
                            <this.ChatMessage message="Hiking sounds amazing! I might catch up on some reading and also meet up with a few friends on Sunday." time="10:50 AM" alignRight={true} />
                        </StyledBox28>
                        <StyledBox26 sx={{
                            display: "flex", border: "3px solid rgba(0, 0, 0, 0.2)",
                            borderRadius: "10px", padding: "15px 30px",
                            alignItems: "center", gap: "10px"
                        }}
                        >
                            {
                                this.state.openEmojiPicker && (
                                    <StyledPicker
                                        style={{
                                            position: "absolute", zIndex: 999, top: "320px"
                                        }}
                                        data-test-id="emojiPickerTestBtn"
                                        onEmojiClick={this.onEmojiClick}
                                        previewConfig={{
                                            showPreview: false
                                        }}
                                    />
                                )
                            }
                            <IconButton
                                onClick={() => this.openEmojiPicker()}
                                data-test-id="emojiIconTestBtn"
                            >
                                <SentimentSatisfiedOutlinedIcon style={{ color: "#A0A0A0" }} />
                            </IconButton>
                            <TextField
                                placeholder="Type a message..."
                                variant="standard"
                                data-test-id="typedMsgTestBtn"
                                InputProps={{ disableUnderline: true }}
                                sx={{
                                    flexGrow: 1, border: "none",
                                    input: {
                                        color: '#000000', fontWeight: 400,
                                        fontSize: "24px", fontFamily: "Manrope",
                                        "@media (max-width: 650px)": {
                                            fontSize: "16px !important"
                                        }
                                    },
                                }}
                                onChange={(event) => this.handleTypedMessage(event)}
                                value={this.state.typedMessageData}
                            />
                            <IconButton>
                                <MicIcon style={{ color: "#A0A0A0" }} />
                            </IconButton>
                            <IconButton>
                                <AttachFileIcon style={{ color: "#A0A0A0" }} />
                            </IconButton>
                            <StyledMessageSendButton
                                style={{
                                    background: "#5E70DD", width: "205px", height: "42px",
                                    borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                                    fontFamily: "Roboto", fontWeight: 700, fontSize: "16px", textTransform: "none",
                                }}
                            > Send
                            </StyledMessageSendButton>
                        </StyledBox26>
                    </Box>
                </StyledBox23>
            </StyledBox22>
        )
    }

    ChatMessage = ({ message, time, alignRight }: any) => {
        return (
            <Grid container alignItems="center" columnGap="20px"
                justifyContent={this.handleChatChecks1(alignRight)}
                flexDirection={this.handleChatChecks2(alignRight)}>
                <StyledPaper1
                    sx={{
                        maxWidth: "50%", padding: "16px 24px", boxSizing: "border-box",
                        backgroundColor: `${this.handleChatChecks3(alignRight)}`,
                        borderRadius: `${this.handleChatChecks4(alignRight)}`,
                        alignSelf: `${this.handleChatChecks5(alignRight)}`,
                        border: `${this.handleChatChecks6(alignRight)}`,
                    }}
                >
                    <StyledTypo22 style={{
                        color: "#515151", fontWeight: 400, fontFamily: "Manrope",
                        fontSize: "18px",
                    }}>
                        {message}
                    </StyledTypo22>
                </StyledPaper1>
                <StyledTypo23 style={{
                    color: "#A0A0A0", fontWeight: 400, fontFamily: "Manrope",
                    fontSize: "12px",
                }}>
                    {time}
                </StyledTypo23>
            </Grid>
        )
    };

    ChatSummary = () => {
        return (
            <StyledBox27 sx={{
                width: "340px", display: "flex",
                gap: "15px", cursor: "pointer"
            }}
                data-test-id="chatMessageClickTestBtn"
                onClick={() => this.handleClickMessageSummary()}
            >
                <ListItemAvatar>
                    <StyledAvatar1 style={{ height: "58px", width: "58px" }}
                        src={bookingProfilePic.default}
                    />
                </ListItemAvatar>
                <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <StyledTypo20 style={{
                        fontWeight: 600, fontFamily: "Manrope", color: "#333333",
                        fontSize: "18px", width: "max-content"
                    }}>
                        Benjamin Knight
                    </StyledTypo20>
                    <StyledTypo21 style={{
                        fontWeight: 400, fontFamily: "Manrope", color: "#5F5F5F",
                        fontSize: "14px", width: "max-content", maxWidth: "155px",
                        textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"
                    }}>
                        Can help with computer Can help with computer Can help with computer Can help with computer
                    </StyledTypo21>
                </Box>
            </StyledBox27>
        )
    }

    getFilteredData = () => {
        const { earningHistoryStatusFilterValue, earningHistorydateFilterData } = this.state;
        let filteredDataItem = earningsData;

        if (earningHistoryStatusFilterValue) {
            filteredDataItem = filteredDataItem.filter((row: any) => row.status === earningHistoryStatusFilterValue);
        }
        if (earningHistorydateFilterData) {
            const selectedDate = dayjs(earningHistorydateFilterData).format('MMM D, YYYY');
            filteredDataItem = filteredDataItem.filter((row: any) => row.date === selectedDate);
        }

        return filteredDataItem;
    };

    renderEarningsHistory() {

        const filteredData = this.getFilteredData();

        const paginatedData = filteredData.slice((this.state.pageState - 1)
            * this.state.rowsPerPage, this.state.pageState * this.state.rowsPerPage);

        return (
            <StyledBox30 style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                <Box style={{ display: 'flex', justifyContent: "flex-end", gap: "15px" }}>
                    <Button variant="outlined" endIcon={<ArrowDropDownIcon />}
                        data-test-id="earningsDateFilterTestBtn"
                        style={{
                            minWidth: "135px", width: "max-content", height: "40px", borderRadius: "8px",
                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                            fontSize: "15px", color: "#161C2D", cursor: "pointer",
                            justifyContent: "center", textTransform: "none"
                        }}
                        sx={{
                            "@media (max-width: 500px)": {
                                minWidth: "65px !important", height: "20px !important",
                                border: "none !important", borderRadius: "3.8px !important",
                                fontSize: "10px !important", background: "#FFFFFF",
                            },
                        }}
                        onClick={() => this.handleEHDateFilter()}
                    >
                        {this.state.earningHistorydateFilterData ? this.state.earningHistorydateFilterData : "Date"}
                    </Button>
                    {this.state.openEHDateFilter && (
                        <Box sx={{ position: 'absolute', zIndex: 1300, visibility: "hidden" }}>
                            <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterDayjs}>
                                <DatePicker open={this.state.openEHDateFilter}
                                    minDate={dayjs()}
                                    data-test-id="earningHistoryDataPickerTestBtn"
                                    onChange={(event) => this.handleEHDateFilterData(event)}
                                    showDaysOutsideCurrentMonth
                                    slotProps={{
                                        day: {
                                            sx: {
                                                '&.MuiPickersDay-root.Mui-selected': {
                                                    border: "1px solid #E1CCFF",
                                                    backgroundColor: '#F0E5FF',
                                                    color: "#6200EA", fontWeight: 700
                                                },
                                                "&.MuiPickersDay-today": {
                                                    border: "none",
                                                    fontWeight: 700, color: "#0F172A",
                                                },
                                                "&.MuiPickersDay-root": {
                                                    fontWeight: 400, fontFamily: "Inter"
                                                }
                                            }
                                        },
                                        popper: {
                                            sx: {
                                                ".MuiPaper-root": {
                                                    position: "absolute",
                                                    top: "-15px", right: "-130px",
                                                    boxShadow: "none",
                                                    border: "1px solid #CBD5E1",
                                                    width: "393px",
                                                    borderRadius: "8.63px",
                                                    "@media (max-width: 500px)": {
                                                        width: "310px",
                                                        right: "-300px", top: "-25px",
                                                    },
                                                },
                                            },
                                        },
                                        layout: {
                                            sx: {
                                                "&.MuiPickersLayout-root": {
                                                    display: "flex", flexDirection: "column"
                                                }
                                            }
                                        },
                                        nextIconButton: {
                                            sx: {
                                                left: "190px"
                                            }
                                        },
                                        calendarHeader: {
                                            sx: {
                                                ".MuiPickersCalendarHeader-label": {
                                                    color: '#0F172A', fontWeight: 700,
                                                    fontSize: '14px', fontFamily: 'Inter',
                                                },
                                                flexDirection: "row-reverse",
                                            }
                                        },
                                        switchViewButton: {
                                            sx: {
                                                display: 'none'
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                    <Select
                        data-test-id="earningsStatusFilterTestBtn"
                        defaultValue={"Status"}
                        style={{
                            width: "135px", height: "40px", borderRadius: "8px",
                            fontSize: "15px", color: "#161C2D", cursor: "pointer",
                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                            justifyContent: "center", textAlign: "center"
                        }}
                        displayEmpty
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    border: '1px solid #BDBDBD',
                                    borderRadius: '0px 0px 10px 10px',
                                    '& .MuiMenuItem-root': {
                                        borderBottom: "1px solid #BDBDBD"
                                    }
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    background: "#F8F8F8", padding: 0,
                                },
                            },
                        }}
                        sx={{
                            "@media (max-width: 500px)": {
                                width: "65px !important", height: "20px !important",
                                border: "none !important", borderRadius: "3.8px !important",
                                fontSize: "10px !important", background: "#FFFFFF",
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: "none",
                            },
                        }}
                        onChange={(event) => this.handleEHStatusFilter(event)}
                    >
                        <MenuItem disabled value={"Status"}>
                            {"Status"}
                        </MenuItem>
                        <MenuItem key={"Paid"} value={"Paid"}>
                            {"Paid"}
                        </MenuItem>
                        <MenuItem key={"Rejected"} value={"Rejected"}>
                            {"Rejected"}
                        </MenuItem>
                    </Select>
                </Box>
                <Box sx={{
                    width: '100%', display: "flex", flexDirection: "column",
                    alignItems: "center", overflow: 'hidden', gap: "40px",
                }}>
                    <StyledEarningsTableContainer sx={{ overflow: "hidden" }}>
                        <StyledTableEarnings stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledHeaderTableCellEarnings style={webStyles.earningsHeaderCell}>Booking ID</StyledHeaderTableCellEarnings>
                                    <StyledHeaderTableCellEarningsDate style={webStyles.earningsHeaderCell}>Date</StyledHeaderTableCellEarningsDate>
                                    <StyledHeaderTableCellEarningsServices style={webStyles.earningsHeaderCell}>Service</StyledHeaderTableCellEarningsServices>
                                    <StyledHeaderTableCellEarnings style={webStyles.earningsHeaderCell}>Amount</StyledHeaderTableCellEarnings>
                                    <StyledHeaderTableCellEarnings style={webStyles.earningsHeaderCell}>Status</StyledHeaderTableCellEarnings>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((row) => (
                                    <TableRow key={row.id}>
                                        <StyledRowTableCellEarningsId style={webStyles.earningsCell}>{row.id}</StyledRowTableCellEarningsId>
                                        <StyledRowTableCellEarningsDate style={webStyles.earningsCell}>{row.date}</StyledRowTableCellEarningsDate>
                                        <StyledRowTableCellEarningsServices style={webStyles.earningsCell}>
                                            <StyledTypo25 style={webStyles.serviceDateInEarnings}>{row.service}</StyledTypo25>
                                            <StyledTypo26 style={webStyles.serviceDateInEarnings}>{row.date}</StyledTypo26>
                                        </StyledRowTableCellEarningsServices>
                                        <StyledRowTableCellEarnings style={webStyles.earningsCell}>{row.amount}</StyledRowTableCellEarnings>
                                        <StyledRowTableCellEarnings style={webStyles.earningsCellStatus}>
                                            {
                                                row.status == "Paid" ? (
                                                    <StyledTypo24 style={webStyles.paidContainer}>{row.status}</StyledTypo24>
                                                ) : (
                                                    <StyledTypo24 style={webStyles.rejectedContainer}>{row.status}</StyledTypo24>
                                                )
                                            }
                                        </StyledRowTableCellEarnings>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTableEarnings>
                    </StyledEarningsTableContainer>
                    <CustomPagination
                        data-test-id="pageChangeTestBtn"
                        count={33}
                        page={this.state.pageState}
                        onChange={this.handleChangePage}
                        shape="rounded"
                        renderItem={(item) => (
                            <PaginationItem
                                components={{
                                    previous: ArrowBackIosIcon,
                                    next: ArrowForwardIosIcon,
                                }}
                                {...item}
                            />
                        )}
                    />
                </Box>
            </StyledBox30>
        )
    }

    renderDirectDepositInfo() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", gap: "60px" }}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <StyledBox31 style={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            <StyledTypo28 style={{
                                width: "196px", fontWeight: 600, color: "#02172C",
                                fontFamily: "Manrope", fontSize: '16px',
                            }}>
                                Billing information
                            </StyledTypo28>
                            <Box style={{
                                display: "flex", flexDirection: "column",
                                maxHeight: "183px", maxWidth: "406px", width: "100%",
                                alignItems: "center",
                                borderRadius: "7px", border: "1px solid #F2F2F2"
                            }}>
                                <Box style={{ boxSizing: "border-box", ...webStyles.billingInfoDetailsDDI }}>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}>Email</StyledTypo27>
                                    <TextField
                                        disabled={this.state.showDDIEditButton}
                                        placeholder="Email"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        sx={{
                                            border: "none", width: "100%", height: "22px", paddingLeft: "40px",
                                            input: {
                                                paddingTop: "0px", paddingBottom: "0px",
                                                color: '#02172C', fontWeight: 400,
                                                fontSize: "16px", fontFamily: "Manrope",
                                                "@media (max-width: 500px)": {
                                                    fontSize: "11.3px !important"
                                                }
                                            },
                                        }}
                                    />
                                </Box>
                                <Box style={{ boxSizing: "border-box", ...webStyles.billingInfoDetailsDDI }}>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}>Address</StyledTypo27>
                                    <TextField
                                        disabled={this.state.showDDIEditButton}
                                        variant="standard"
                                        placeholder="Address"
                                        InputProps={{ disableUnderline: true }}
                                        sx={{
                                            border: "none", width: "100%", height: "22px", paddingLeft: "20px",
                                            input: {
                                                "@media (max-width: 500px)": {
                                                    fontSize: "11.3px !important"
                                                },
                                                fontSize: "16px", fontFamily: "Manrope",
                                                paddingTop: "0px", paddingBottom: "0px",
                                                color: '#02172C', fontWeight: 400,
                                            },
                                        }}
                                    />
                                </Box>
                                <Box style={{ boxSizing: "border-box", ...webStyles.billingInfoDetailsDDI }}>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}>City</StyledTypo27>
                                    <TextField
                                        disabled={this.state.showDDIEditButton}
                                        placeholder="City"
                                        variant="standard"
                                        sx={{
                                            border: "none", width: "100%", height: "22px", paddingLeft: "54px",
                                            input: {
                                                fontSize: "16px", fontFamily: "Manrope",
                                                color: '#02172C', fontWeight: 400,
                                                paddingTop: "0px", paddingBottom: "0px",
                                                "@media (max-width: 500px)": {
                                                    fontSize: "11.3px !important"
                                                }
                                            },
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Box>
                                <Box style={{ boxSizing: "border-box", gap: "28px", ...webStyles.billingInfoDetailsDDI }}>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}> State</StyledTypo27>
                                    <Select
                                        disabled={this.state.showDDIEditButton}
                                        placeholder="State"
                                        style={{
                                            width: "100%", maxWidth: "79px", height: "22px"
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                        defaultValue={1}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            fontSize: "16px", fontFamily: "Manrope",
                                            color: '#02172C', fontWeight: 400,
                                            "@media (max-width: 500px)": {
                                                fontSize: "11.3px !important"
                                            }
                                        }}
                                    >
                                        <MenuItem key={1} value={1}>NT</MenuItem>
                                        <MenuItem key={2} value={2}>AM</MenuItem>
                                    </Select>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}>ZIP</StyledTypo27>
                                    <TextField
                                        disabled={this.state.showDDIEditButton}
                                        placeholder="ZIP"
                                        variant="standard"
                                        sx={{
                                            border: "none", width: "100%", paddingLeft: "14px",
                                            input: {
                                                fontSize: "16px", fontFamily: "Manrope",
                                                color: '#02172C', fontWeight: 400,
                                                "@media (max-width: 650px)": {
                                                    fontSize: "11.3px !important"
                                                }
                                            },
                                        }}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Box>
                                <Box style={{ boxSizing: "border-box", ...webStyles.billingInfoDetailsDDI }}>
                                    <StyledTypo27 style={webStyles.billInfoTextDDI}>Country</StyledTypo27>
                                    <Select
                                        disabled={this.state.showDDIEditButton}
                                        placeholder="Country"
                                        style={{
                                            width: "100%", height: "22px"
                                        }}
                                        defaultValue={1}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            fontSize: "16px", fontFamily: "Manrope",
                                            color: '#02172C', fontWeight: 400,
                                            "@media (max-width: 500px)": {
                                                fontSize: "11.3px !important"
                                            }
                                        }}
                                    >
                                        <MenuItem key={1} value={1}>United States</MenuItem>
                                        <MenuItem key={2} value={2}>United Knigdom</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                        </Box>
                        <StyledBox32 style={{ display: "flex", flexDirection: "column", gap: "10px", marginRight: "75px" }}>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                    Bank Account Type
                                </StyledTypo29>
                                <StyledDDISelect
                                    disabled={this.state.showDDIEditButton}
                                    style={{
                                        height: "46px", width: "406px", borderRadius: "7px",
                                        border: "1px solid #F2F2F2", fontFamily: "Manrope", fontWeight: 400,
                                        fontSize: "16px", color: "#02172C", outline: "none",
                                        justifyContent: "center", cursor: "pointer"
                                    }}
                                    defaultValue={1}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#5D5D5D",
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    <MenuItem key={1} value={1}>Personal</MenuItem>
                                    <MenuItem key={2} value={2}>Savings</MenuItem>
                                </StyledDDISelect>
                            </Box>
                            {this.state.showDDIEditButton ? (
                                <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                        ACH Bank Account Number
                                    </StyledTypo29>
                                    <StyledDDITextField
                                        disabled={this.state.showDDIEditButton}
                                        type="text"
                                        style={{
                                            border: "1px solid #F2F2F2", justifyContent: "center",
                                            height: "46px", borderRadius: "7px", width: "406px"
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#5D5D5D",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                            input: {
                                                fontWeight: 400, fontFamily: "Manrope",
                                                fontSize: "16px", color: "#02172C",
                                            },
                                        }}
                                        placeholder="ACH Bank Account Number"
                                    >
                                    </StyledDDITextField>
                                    <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                        Bank Routing Number
                                    </StyledTypo29>
                                    <StyledDDITextField
                                        disabled={this.state.showDDIEditButton}
                                        type="text"
                                        style={{
                                            border: "1px solid #F2F2F2", justifyContent: "center",
                                            height: "46px", borderRadius: "7px", width: "406px"
                                        }}
                                        sx={{
                                            input: {
                                                fontWeight: 400, fontFamily: "Manrope",
                                                fontSize: "16px", color: "#02172C",
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#5D5D5D",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                        }}
                                        placeholder="Bank Routing Number"
                                    >
                                    </StyledDDITextField>
                                </Box>
                            ) : (
                                <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                        Checking or Savings Account Number
                                    </StyledTypo29>
                                    <StyledDDITextField
                                        type="text"
                                        style={{
                                            width: "406px", justifyContent: "center",
                                            height: "46px", borderRadius: "7px",
                                            border: "1px solid #F2F2F2",
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#5D5D5D",
                                            },
                                            input: {
                                                fontWeight: 400, fontFamily: "Manrope",
                                                fontSize: "16px", color: "#02172C",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                        }}
                                        placeholder="Account Number"
                                    >
                                    </StyledDDITextField>
                                    <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                        Confirm Checking or Savings Account Number
                                    </StyledTypo29>
                                    <StyledDDITextField
                                        type="text"
                                        style={{
                                            height: "46px", width: "406px",
                                            border: "1px solid #F2F2F2", justifyContent: "center",
                                            borderRadius: "7px",
                                        }}
                                        placeholder="Confirm Account Number"
                                        sx={{
                                            input: {
                                                fontSize: "16px", color: "#02172C",
                                                fontWeight: 400, fontFamily: "Manrope",
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#5D5D5D",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                        }}
                                    >
                                    </StyledDDITextField>
                                    <StyledTypo29 style={webStyles.bankAccountTextDDI}>
                                        9-digit Bank Routing Number
                                    </StyledTypo29>
                                    <StyledDDITextField
                                        type="text"
                                        style={{
                                            border: "1px solid #F2F2F2", height: "46px",
                                            borderRadius: "7px", width: "406px", justifyContent: "center",
                                        }}
                                        sx={{
                                            input: {
                                                fontWeight: 400, color: "#02172C",
                                                fontSize: "16px", fontFamily: "Manrope",
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#5D5D5D",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none'
                                            },
                                        }}
                                        placeholder="Bank Routing Number"
                                    >
                                    </StyledDDITextField>
                                </Box>
                            )}
                        </StyledBox32>
                    </StyledBox31>
                    <StyledBox33>
                        <StyledEditButtonDDI style={{
                            height: "30px", width: "163px", color: "#5E70DD",
                            borderRadius: "30px", border: "1px solid #5E70DD",
                            textTransform: "none", fontWeight: 600,
                            fontFamily: "Manrope", fontSize: '12px',
                            visibility: this.checkDDIEditBtnVisibility()
                        }}
                            data-test-id="editDDITestBtn"
                            onClick={() => this.handleDDIEditBtn()}
                        > Edit
                        </StyledEditButtonDDI>
                    </StyledBox33>
                </Box>
                {this.state.showDDISaveButton && (
                    <StyledSaveButtonDDI
                        style={{
                            background: "#5E70DD", width: "202px", height: "41px",
                            borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                            fontFamily: "Roboto", fontWeight: 700, fontSize: "16px",
                            textTransform: "none", alignSelf: "flex-end",
                        }}
                        data-test-id="saveDDITestBtn"
                        onClick={() => this.handleDDISaveBtn()}
                    > Save
                    </StyledSaveButtonDDI>
                )}
            </Box>
        )
    }

    renderAvailability = () => {
        return <> {this.state.openAllBookingsOrChooseAvailability == "Choose Availability" && <AvailWrapper>
            <Typography className="longText">Set your work availability here. You can set buffer time between jobs, and dates you can't work. Potential clients will only see times outside of school hours that haven't been booked yet.</Typography>
            <Box className="mainBoxWrap" >

                <Box className="boxwrp">
                    <Box style={{ paddingBottom: "32.44px" }}>
                        <Typography className="availText">Choose Availability</Typography>
                        <Select className="selectBox"
                            IconComponent={KeyboardArrowDownIcon}
                            defaultValue={1}
                        >
                            <MenuItem key={1} value={1}> Set school hours</MenuItem>
                        </Select>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10.7px" }}>

                        <Box className="commonDesign">
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>

                                <Typography className="commonText">Monday</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box style={{ display: "flex", gap: "21.78px", height: "21px", alignItems: "center" }}>
                                        <Checkbox value={true} style={{ width: "18.34px", height: "18.34px" }} />
                                        <Typography className="commonTimeText" >3:30 PM To 5:00 PM</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5" }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px" }}>3:30 PM To 7:00 PM</Typography>
                            </Box>

                        </Box>

                        <Box className="commonDesign">
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                <Typography className="commonText">Tuesday</Typography>
                                <Box style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                                    <Box style={{ display: "flex", height: "21px", alignItems: "center", gap: "21.78px", }}>
                                        <Checkbox
                                            value={true}
                                            sx={{
                                                height: '18.34px',
                                                width: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText" >3:30 PM To 5:00 PM</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ width: "21.5", height: "21.5px", }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px" }}>3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>

                        <Box className="commonDesign" style={{ justifyContent: "center", opacity: "0.4" }}>
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                <Typography className="commonText" style={{ display: "none" }}>Wednesday</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box style={{ display: "flex", gap: "21.78px", height: "21px", alignItems: "center" }}>
                                        <Checkbox value={true}
                                            sx={{
                                                width: '18.34px',
                                                height: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText" >Wednesday</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5" }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px", display: "none" }}>3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>

                        <Box className="commonDesign">
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px", }}>
                                <Typography className="commonText">Thursday</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                    <Box style={{ display: "flex", gap: "21.78px", height: "21px", alignItems: "center", }}>
                                        <Checkbox
                                            value={true}
                                            sx={{
                                                width: '18.34px', height: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText" >3:30 PM To 5:00 PM</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5", }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px", }}>3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>
                        <Box className="commonDesign" style={{ justifyContent: "center", opacity: "0.4" }}>
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                <Typography className="commonText" style={{ display: "none" }}>Friday</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box style={{ display: "flex", gap: "21.78px", height: "21px", alignItems: "center" }}>
                                        <Checkbox value={true}
                                            sx={{
                                                width: '18.34px',
                                                height: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText" >Friday</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5" }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px", display: "none" }}>3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>
                        <Box className="commonDesign">
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                <Typography className="commonText">Saturday</Typography>
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                    <Box style={{ display: "flex", height: "21px", gap: "21.78px", alignItems: "center" }}>
                                        <Checkbox value={true}
                                            sx={{
                                                height: '18.34px',
                                                width: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText">3:30 PM To 5:00 PM</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5" }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px" }}
                                >3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>
                        <Box className="commonDesign">
                            <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                <Typography className="commonText">Sunday</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box style={{ display: "flex", gap: "21.78px", height: "21px", alignItems: "center" }}>
                                        <Checkbox value={true}
                                            sx={{
                                                width: '18.34px',
                                                height: '18.34px',
                                                '&.Mui-checked': {
                                                    color: '#5E70DD',
                                                },
                                            }} />
                                        <Typography className="commonTimeText" >3:30 PM To 5:00 PM</Typography>
                                    </Box>
                                    <img src={plusIcon.default} style={{ height: "21.5px", width: "21.5" }} />
                                </Box>
                                <Typography className="commonTimeText" style={{ paddingLeft: "39px" }}>3:30 PM To 7:00 PM</Typography>
                            </Box>
                        </Box>
                    </Box>


                </Box>
                <SideWrapper className="sideBox">
                    <Typography className="availText" style={{ paddingBottom: "18.46" }}>Buffer time between jobs</Typography>
                    <Box style={{ display: "flex", justifyContent: "space-between", maxWidth: "393.24px", width: "100%" }}>
                        <Box style={{ maxWidth: "181px", width: "100%" }}>
                            <Typography className="befText">Before</Typography>
                            <Select className="slect"

                                defaultValue={1}>
                                <MenuItem key={1} value={1} style={{ display: 'flex', alignItems: 'center' }}> <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                    <img src={watchIcon.default} style={{ width: "24.08px", height: "24.88px" }} />  </ListItemIcon> 15 min</MenuItem>
                            </Select>
                        </Box>
                        <Box style={{ maxWidth: "181px", width: "100%" }}>
                            <Typography className="befText">After</Typography>
                            <Select className="slect"
                                defaultValue={1}
                            >
                                <MenuItem key={1} value={1} style={{ display: 'flex', alignItems: 'center' }}> <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                                    <img src={watchIcon.default} style={{ width: "24.08px", height: "24.88px" }} />  </ListItemIcon>30 min</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Typography className="availText" style={{ paddingTop: "22.17px" }}>TimeZone</Typography>
                    <TextField
                        value={"(GMT 7:00)  Pacific Time - Los Angeles"}
                        style={{ maxWidth: "393.24px", width: "100%" }}
                        data-test-id="handleTextID"
                        onChange={(event) => this.handleTextChange(event)}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontFamily: "Manrope",
                                fontSize: "14.34px",
                                fontWeight: 600,
                                lineHeight: "22.94px",
                                color: "#010101"
                            },
                            '.MuiOutlinedInput-root': {
                                height: "44.5px",
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: "0.9px solid #D6DDEB",
                                borderRadius: "7.17px",
                            },
                        }}
                    />

                    <TextField
                        value={this.state.unAvailableDates}
                        placeholder="Add dates you cannot work (sick, holidays, travels)"
                        style={{ maxWidth: "393.24px", width: "100%", marginTop: "32.1px" }}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontFamily: "Manrope",
                                fontSize: "13.38px",
                                fontWeight: 600,
                                lineHeight: "21.4px",
                                color: "#404040"
                            },
                            '.MuiOutlinedInput-root': {
                                height: "56.18px",
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: "0.9px solid #D6DDEB",
                                borderRadius: "7.17px",
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <img src={plusIcon.default} onClick={this.handleCalender}
                                        style={{ width: "21.5px", height: "21.5px" }} alt="Plus Icon" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box style={{ paddingTop: "24.4px" }}>
                        {this.state.calenderStatus && <> <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterDayjs}>
                            <DatePicker open={this.state.calenderStatus}
                                data-test-id="calenderPopupTestBtnID"
                                onChange={(event) => this.handleUnavailableDatePicker(event)}
                                minDate={dayjs()}
                                showDaysOutsideCurrentMonth
                                slotProps={{
                                    day: {
                                        sx: {
                                            '&.MuiPickersDay-root.Mui-selected': {
                                                backgroundColor: '#F0E5FF',
                                                border: "1px solid #E1CCFF",
                                                color: "#6200EA", fontWeight: 700
                                            },
                                            "&.MuiPickersDay-today": {
                                                border: "none", color: "#0F172A",
                                                fontWeight: 700,
                                            },
                                            "&.MuiPickersDay-root": {
                                                fontWeight: 400,
                                                fontFamily: "Inter"
                                            }
                                        }
                                    },
                                    popper: {
                                        sx: {
                                            ".MuiPaper-root": {
                                                top: "-60px",
                                                position: "absolute",
                                                border: "1px solid #CBD5E1",
                                                boxShadow: "none",
                                                borderRadius: "8px",
                                                width: "393px",
                                                "@media (max-width: 500px)": {
                                                    width: "310px",
                                                },
                                            },
                                        },
                                    },
                                    layout: {
                                        sx: {
                                            "&.MuiPickersLayout-root": {
                                                display: "flex",
                                                flexDirection: "column"
                                            }
                                        }
                                    },
                                    nextIconButton: {
                                        sx: {
                                            left: "190px"
                                        }
                                    },
                                    calendarHeader: {
                                        sx: {
                                            ".MuiPickersCalendarHeader-label": {
                                                color: '#0F172A', fontSize: '14px',
                                                fontFamily: 'Inter', fontWeight: 700
                                            },
                                            flexDirection: "row-reverse",
                                        }
                                    },
                                    switchViewButton: {
                                        sx: {
                                            display: 'none'
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                            <Box style={{ display: "flex", gap: "11.1px", justifyContent: "center", position: "relative", top: "300px" }}>
                                <Button className="cancelBtn"
                                    onClick={this.handleCalender}
                                >Cancel</Button>
                                <Button className="DateBtn"  data-test-id ="unavailDateId" onClick={() => this.handleCalender()}>Add Date Unavailable</Button>
                            </Box>
                        </>


                        }
                    </Box>
                </SideWrapper>


            </Box>
        </AvailWrapper>} </>
    }

    addNewCardBox = () => {
        return <Box style={webStyles.containerStyle}>
            <ModifiedCard>
                <Box style={webStyles.innerBoxStyle}>
                    <Box style={{ padding: "24px 32px", display: "flex", flexDirection: "column", gap: "24px", }}>

                        <Box style={webStyles.headerBoxStyle}>
                            <img src={vector.default} />
                            <Typography style={webStyles.TypographyStyle1}>
                                Add new card
                            </Typography>
                        </Box>
                        <StylesCard>
                            <Typography style={webStyles.TypographyStyle}>
                                Name on card
                            </Typography>
                            <input style={webStyles.textFieldStyle} />
                        </StylesCard>
                        <Box style={webStyles.cardNumberStyle}>
                            <Typography style={{ ...webStyles.TypographyStyle, paddingRight: "20px" }}>
                                Card number
                                <Typography style={{
                                    fontSize: '12px', color: 'rgba(94, 94, 94, 1)',
                                    fontFamily: "Manrope", fontWeight: 400, lineHeight: "16.39px"
                                }}>
                                    Enter the 16-digit card number on the card
                                </Typography>
                            </Typography>
                            <TextField
                                variant="outlined"
                                style={webStyles.textFieldStyle}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        height: "48px",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }}
                                InputProps={{ startAdornment: <img src={darkCard.default} /> }}
                            />
                            <img src={tickIcon.default} style={{ paddingLeft: "12px" }} />
                        </Box>
                        <StylesCard3>
                            <Typography style={webStyles.TypographyStyle}>
                                Expiry date
                            </Typography>
                            <Box style={{ display: 'flex', gap: '5px', height: "48px", alignContent: 'center' }}>
                                <input style={webStyles.expiryInputStyle} />
                                <Typography style={{ fontSize: '24px', margin: '4px', fontWeight: 400 }}>/</Typography>
                                <input style={{ ...webStyles.expiryInputStyle, marginRight: "10px" }} />
                                <Typography style={{
                                    margin: '8px', fontWeight: 400, fontSize: '16px', fontFamily: "Manrope",
                                    lineHeight: "21.86px", color: "#000000"
                                }}>
                                    CVV
                                    <Typography style={{
                                        fontFamily: "Manrope", lineHeight: "19.12px",
                                        fontSize: 14, fontWeight: 300, color: "#5E5E5E"
                                    }}>
                                        Security Code
                                    </Typography>
                                </Typography>
                                <input style={{ ...webStyles.expiryInputStyle, maxWidth: "64px" }} />
                            </Box>
                        </StylesCard3>
                    </Box>
                </Box>
                <Box style={webStyles.checkBoxContainerStyle}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.checkedA}
                                onChange={this.handleChange}
                                name="save"
                                color="primary"
                                style={{
                                    fontFamily: "Manrope", fontSize: "16px",
                                    fontWeight: 400, lineHeight: "21.86px"
                                }}
                            />
                        }
                        label="Save"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.checkedB}
                                onChange={this.handleChange}
                                name="setAsDefault"
                                color="primary"
                                style={{
                                    fontFamily: "Manrope", fontSize: "16px",
                                    fontWeight: 400, lineHeight: "21.86px"
                                }}
                            />
                        }
                        style={{ whiteSpace: 'nowrap' }}
                        label="Save as default"
                    />
                </Box>
            </ModifiedCard>
        </Box>
    }

    renderPaymentScreen = () => {
        const rows = [
            {
                service: "Web Hosting",
                date: "2024-09-25",
                amount: "$50",
                status: "Paid",
            },
            {
                service: "Domain Registration",
                date: "2024-09-20",
                amount: "$15",
                status: "Rejected",
            },
        ];

        return <Box>
            <Box style={{ display: "flex", gap: "40px", paddingBottom: "35px" }}>
                <Typography style={{ ...webStyles.payText, color: `${!this.state.activeTab ? "#000000" : "#7C8493"}` }} data-test-id="falseId" onClick={() => this.manageTabs(false)}>Payment History</Typography>
                <Typography style={{ ...webStyles.payText, color: `${this.state.activeTab ? "#000000" : "#7C8493"}` }} data-test-id="trueId" onClick={() => this.manageTabs(true)}>Payment Method</Typography>
            </Box>
            <Box>
                {this.state.activeTab ? <StylesCard2>
                    <StylesCard5>
                        <Typography style={{ ...webStyles.payInfo }}>Payment information</Typography>
                        <Button style={webStyles.payButton}>Update Payment Method</Button>
                        <Box style={webStyles.cardInfo}>
                            <StylesCard4>

                                <Typography style={{
                                    fontFamily: "Manrope", fontSize: "14px", fontWeight: 400, lineHeight: "19.12px",
                                    letterSpacing: "0.0114em", color: "#000000"
                                }}>Card</Typography>
                                <Box style={{ display: "flex" }}>

                                    <img src={cardIcon.default} style={{ marginRight: "8px" }} />
                                    <input
                                        type="text"
                                        value={"0000 0000 0000 0000"}
                                        style={{ border: "none", ...webStyles.cardText }}
                                    />
                                    <input
                                        type="text"
                                        value={"09/29"}
                                        style={{ border: "none", width: "50px", ...webStyles.cardText }}
                                    />
                                </Box>
                            </StylesCard4>
                        </Box>
                        <Box style={{ display: "flex", gap: "12px", marginTop: "8px", alignItems: "center" }}>
                            <img src={lockIcon.default} /> <Typography style={webStyles.secureText}>Secured by Stripe</Typography>
                        </Box>
                        <Box style={{ marginTop: "45px" }}>
                            <Typography style={webStyles.billInfo}>Billing information</Typography>
                        </Box>
                        <Box style={webStyles.billInfoBox}>
                            <Box style={webStyles.contentAlign}>
                                <span style={webStyles.spanText}>Email</span><input type="text" placeholder="Enter email" style={{ width: "100%", maxWidth: "161px", height: "16px", ...webStyles.inputText }} />
                            </Box>
                            <Box style={{ border: "1px solid #F2F2F2", width: "100%" }}></Box>
                            <Box style={webStyles.contentAlign}>
                                <span style={webStyles.spanText}>Street</span><input type="text" placeholder="1234 Test Dr, Clearwater, FL" style={{ width: "100%", maxWidth: "161px", height: "16px", ...webStyles.inputText }} />
                            </Box>
                            <Box style={{ border: "1px solid #F2F2F2", width: "100%" }}></Box>
                            <Box style={webStyles.contentAlign}>
                                <span style={webStyles.spanText}>City</span><input type="text" placeholder="Clearwater" style={{ width: "100%", maxWidth: "161px", height: "16px", ...webStyles.inputText }} />
                            </Box>
                            <Box style={{ border: "1px solid #F2F2F2", width: "100%" }}></Box>
                            <Box style={webStyles.contentAlign}>
                                <span style={webStyles.spanText}> State</span>
                                <Select
                                    placeholder="NT"
                                    style={{ width: "100%", maxWidth: "145px", height: "16px", ...webStyles.inputText }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    defaultValue={1}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    <MenuItem key={1} value={1}>option1</MenuItem>
                                    <MenuItem key={2} value={2}>option2</MenuItem>
                                </Select>
                                <span style={webStyles.spanText}>Zipcode</span> <input type="text" placeholder="1000" style={{ width: "100%", maxWidth: "42px", height: "16px", ...webStyles.inputText }} />
                            </Box>
                            <Box style={{ border: "1px solid #F2F2F2", width: "100%" }}></Box>
                            <Box style={webStyles.contentAlign}>
                                <span style={webStyles.spanText}> Country</span>
                                <img src={flagIcon.default} />
                                <Select
                                    placeholder="NT"
                                    style={{ width: "100%", maxWidth: "232px", height: "19px", ...webStyles.inputText }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    defaultValue={1}
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    <MenuItem key={1} value={1}>option1</MenuItem>
                                    <MenuItem key={2} value={2}>option2</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </StylesCard5>
                    {this.state.addCard ? <>{this.addNewCardBox()}</> : <Box style={webStyles.addCardDiv}>
                        <img src={vector.default} onClick={this.handleAddCard} />
                        <Typography style={webStyles.payInfo} onClick={this.handleAddCard}>Add new card</Typography>
                    </Box>}
                </StylesCard2> :
                    <TableComponent>
                        <TableContainer style={{ boxShadow: "none" }} component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeadText" sx={{ width: "212px !important" }}>Service</TableCell>
                                        <TableCell align="right" className="tableHeadText" >Date</TableCell>
                                        <TableCell align="right" className="tableHeadText" >Amount</TableCell>
                                        <TableCell align="right" className="tableHeadText" >Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={"row.name"}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                '& td, & th': { border: 0 }
                                            }}
                                        >
                                            <TableCell component="th" scope="row" >
                                                <Box style={{ display: "flex", gap: "12px", height: "75px", alignItems: "center" }}>
                                                    <img src={bookingProfilePic.default} style={{ maxWidth: "75px", width: "100%", height: "71px" }} />
                                                    <Box>
                                                        <Typography className="rowNameText">Jack Tohas</Typography>
                                                        <Typography className="rowTextTypo">Intergenerational Activities</Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right" className="tableText">{row.date}</TableCell>
                                            <TableCell align="right" className="tableText">{row.amount}</TableCell>
                                            <TableCell align="right">{(row?.status == "Paid") ? <Button className="paidBtn">Paid</Button>
                                                : <Button className="rejectBtn" onClick={this.handleModal}>Rejected</Button>}
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </TableComponent>

                }
            </Box>

            <Modal
                open={this.state.histroyModal}
                onClose={this.closeModal}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <ModalContainer>
                    <Box style={{ display: "flex", justifyContent: "flex-end", padding: "16px" }}>
                        <img src={closeIcon.default} style={{ height: "32px", width: "32px" }} onClick={this.closeModal} />
                    </Box>
                    <Box className="modWrap">
                        <Typography className="typoTitle">Transaction Details</Typography>
                        <Box className="mainWrapper">
                            <Box style={{ display: "flex", gap: "12px", height: "75px", alignItems: "center" }}>
                                <img src={bookingProfilePic.default} style={{ maxWidth: "75px", width: "100%", height: "71px" }} />
                                <Box>
                                    <Typography className="rowNameText">Jack Tohas</Typography>
                                    <Typography className="rowTextTypo">Intergenerational Activities</Typography>
                                </Box>
                            </Box>

                            <Box className="contentWrapper">
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="greyText">Date</Typography>
                                    <Typography className="blackText">Mar 22, 2023</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="greyText">Payment Method</Typography>
                                    <Typography className="blackText">Credit Card</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="greyText">Time</Typography>
                                    <Typography className="blackText">2 hours</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="greyText">Amount $100</Typography>
                                    <Typography className="blackText"></Typography>
                                </Box>
                                <Typography className="credText">Credit Card Rejected </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ width: "100%", border: "1px solid #E2E8F0", marginTop: "32px", marginBottom: "25px" }}></Box>
                    <Box className="btnAlignment">
                        <Button className="credBtn" data-test-id="credId" onClick={() => this.manageTabs(true)} >
                            Update Credit Card Details
                        </Button>
                    </Box>

                </ModalContainer>
            </Modal>

        </Box>
    }

    render() {
        return (
            <MainBox style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <StyledBox15 style={{
                    height: "74px", display: "flex", alignItems: "center",
                    borderBottom: "1px solid #C1C7CD", gap: "16px", padding: "0px 60px 0px 40px"
                }}>
                    <Box style={{ display: "flex", alignItems: "center", flexGrow: 1, gap: "40px" }}>
                        <img
                            src={logo.default}
                            alt="senior_year"
                            style={{ width: "195px", height: "22px" }}
                        />
                    </Box>
                    <StyledBox13 style={{ display: "none", cursor: "pointer" }}>
                        <img src={userIcon.default} />
                    </StyledBox13>
                    <StyledBox14 style={{
                        display: "flex", alignItems: "center", gap: "16px"
                    }}>
                        <Divider orientation="vertical" flexItem />
                        <Typography style={{
                            width: "fit-content", height: "26px", color: "#020202", fontFamily: "Manrope",
                            fontSize: "16px", fontWeight: 500, cursor: "pointer"
                        }}>
                            {this.state.userFullName}
                        </Typography>
                    </StyledBox14>
                </StyledBox15>
                <StyledBox4 style={{ display: "flex", gap: "20px", padding: "30px 60px", flexDirection: "column" }}>
                    <StyledTypo1 style={{
                        height: "29px", color: "#25324B",
                        fontSize: "24px", fontWeight: 700, fontFamily: "Manrope",
                    }}>
                        My Profile
                    </StyledTypo1>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <StyledTab
                            data-test-id="tabsTestBtn"
                            value={this.state.currentTabIndex}
                            onChange={this.handleTabChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: '#5E70DD',
                                },
                            }}
                            sx={{
                                ".Mui-selected": {
                                    color: "#25324B !important"
                                },
                            }}
                        >
                            {!this.hideTabs() && <Tab value="4" sx={webStyles.tabs} label="Overview" />}
                            <Tab value="0" sx={webStyles.tabs} label="Bookings" />
                            <Tab value="2" sx={webStyles.tabs} label="Messages" />
                            {this.hideTabs() && <Tab value="1" sx={webStyles.tabs} label="Earnings History" />}
                            {!this.hideTabs() && <Tab value="3" sx={webStyles.tabs} label="Payments" />}
                            {this.hideTabs() && <Tab value="7" sx={webStyles.tabs} label="Direct Deposit Info" />}
                            {this.hideTabs() && <Tab value="4" sx={webStyles.tabs} label="Profile" />}
                            {this.hideTabs() && <Tab value="5" sx={webStyles.tabs} label="Work Preferences and Interests" />}
                            <Tab value="6" sx={webStyles.tabs} label="Settings" />
                        </StyledTab>
                    </Box>
                    {(this.state.currentTabIndex == "6") && <Settings2 navigation={this.props.navigation} id={"1"} />}
                    {(this.state.showEditButton && this.state.currentTabIndex == "4") &&
                        <Typography style={{
                            width: "fit-content", height: "19px", color: "#548CFF",
                            fontSize: "16px", fontWeight: 600, fontFamily: "Roboto",
                            alignSelf: "flex-end", cursor: "pointer"
                        }}
                            data-test-id="editTestBtn"
                            onClick={() => this.handleEdit()}
                        > Edit
                        </Typography>
                    }
                    {this.state.currentTabIndex == "4" &&
                        <StyledBox27 style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                            <StyledBox1 style={{ display: "flex", gap: "50px", }}>
                                <StyledBox3 style={{
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                    height: "148px", width: "148px", cursor: "pointer",
                                    border: `${this.userProfileBorder()}`, borderRadius: "100%",
                                    background: "#5E70DD", flexDirection: "column",
                                }}
                                    data-test-id="pictureUploadBoxTest"
                                    onClick={this.uploadPicture}
                                >
                                    <input
                                        data-test-id="inputFileTest"
                                        ref={this.refElmFile}
                                        type="file"
                                        disabled={this.state.showEditButton}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={(event) => this.selectPicture(event)}
                                    />
                                    {!this.state.profileImageUrl &&
                                        <>
                                            <CameraAltOutlinedIcon style={{ color: "#FFFFFF" }} />
                                            <StyledTypo18 style={{
                                                width: "fit-content", height: "23px", textAlign: "center", color: "#FFFFFF",
                                                fontFamily: "Inter", fontWeight: 700, fontSize: "14px"
                                            }}
                                            > Edit picture
                                            </StyledTypo18>
                                        </>
                                    }
                                    {(this.state.profileImageUrl?.length > 0) &&
                                        <StyledUserProfileImg
                                            src={this.state.profileImageUrl}
                                            alt="profile_image"
                                            onClick={this.uploadPicture}
                                            style={{ height: "148px", width: "148px", borderRadius: "100%" }}
                                        />
                                    }
                                </StyledBox3>
                                <StyledBox34 style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    <StyledBox2 style={{ display: "flex", gap: "40px" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <StyledTypo19 style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > First Name
                                            </StyledTypo19>
                                            <StyledTextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="firstNameTestBtn"
                                                type="text"
                                                value={this.state.userFirstName}
                                                style={{
                                                    height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", justifyContent: "center",
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={(event) => this.changeFirstName(event)}
                                                placeholder="First Name"
                                            >
                                            </StyledTextField>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <StyledTypo19 style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Last Name
                                            </StyledTypo19>
                                            <StyledTextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="lastNameTestBtn"
                                                type="text"
                                                value={this.state.userLastName}
                                                style={{
                                                    height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center",
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={(event) => this.changeLastName(event)}
                                                placeholder="Last Name"
                                            >
                                            </StyledTextField>
                                        </Box>
                                    </StyledBox2>
                                    <StyledBox2 style={{ display: "flex", gap: "40px" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <StyledTypo19 style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Email
                                            </StyledTypo19>
                                            <StyledTextField
                                                disabled={this.state.showEditButton}
                                                data-test-id="emailTestBtn"
                                                type="email"
                                                value={this.state.userEmail}
                                                style={{
                                                    height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center",
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={(event) => this.changeEmail(event)}
                                                placeholder="Email"
                                            >
                                            </StyledTextField>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <StyledTypo19 style={{
                                                width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                                fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                            }}
                                            > Cell Number
                                            </StyledTypo19>
                                            <StyledTextField
                                                data-test-id="numberTestBtn"
                                                disabled={this.state.showEditButton}
                                                type="text"
                                                value={this.state.userNumber}
                                                style={{
                                                    height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: `${this.handleErrorBorder1()}`, fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center",
                                                }}
                                                sx={{
                                                    input: {
                                                        color: '#515B6F', fontWeight: 600,
                                                        fontSize: "16px", fontFamily: "Manrope"
                                                    },
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                onChange={(event) => this.changeNumber(event)}
                                                placeholder="Cell Number"
                                            >
                                            </StyledTextField>
                                            {
                                                !this.state.isPhoneNumberValid &&
                                                <Typography style={{
                                                    width: "fit-content", textAlign: "start", color: "#DC2626",
                                                    fontFamily: "Inter", fontWeight: 400, fontSize: "12px"
                                                }}
                                                > {this.cellNumberHelperText1()}
                                                </Typography>
                                            }
                                        </Box>
                                    </StyledBox2>
                                    <StyledBox2 style={{
                                        display: `${this.hideBoxesReverse()}`,
                                    }}>
                                        <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                            <StyledTypo19 style={{
                                                fontWeight: 600, textAlign: "start", color: "#515B6F",
                                                height: "26px", fontFamily: "Manrope",
                                                fontSize: "16px", width: "fit-content",
                                            }}
                                            > {`${this.addressParameter()}`}
                                            </StyledTypo19>
                                            <StyledTextFieldAddress
                                                type="text"
                                                data-test-id="streetTestBtn"
                                                disabled={this.state.showEditButton}
                                                style={{
                                                    width: `${this.addressParameterWidth()}`,
                                                    height: "50px", borderRadius: "8px", textAlign: "center",
                                                    border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                                    fontSize: "16px", outline: "none",
                                                    justifyContent: "center",
                                                }}
                                                value={this.state.userStreetAddress}
                                                onChange={(event) => this.changeStreet(event)}
                                                sx={{
                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#010101",
                                                    },
                                                    input: {
                                                        fontSize: "16px", color: '#515B6F',
                                                        fontWeight: 600, fontFamily: "Manrope",
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                }}
                                                placeholder={`${this.addressParameter()}`}
                                            >
                                            </StyledTextFieldAddress>
                                        </Box>
                                    </StyledBox2>
                                </StyledBox34>
                            </StyledBox1>
                            <StyledBox1 style={{ display: `${this.hideBoxes()}`, gap: "2%" }}>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > Birth Date
                                    </StyledTypo19>
                                    <StyledTextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="birthDateTestBtn"
                                        type="text"
                                        value={this.state.userBirthDate}
                                        style={{
                                            height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center",
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }}
                                        onChange={(event) => this.changeBirthDate(event)}
                                        placeholder="Birth Date"
                                    >
                                    </StyledTextField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > School
                                    </StyledTypo19>
                                    <StyledTextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="schoolTestBtn"
                                        type="text"
                                        value={this.state.userSchool}
                                        style={{
                                            height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center",
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeSchool(event)}
                                        placeholder="School"
                                    >
                                    </StyledTextField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > Grade
                                    </StyledTypo19>
                                    <StyledSelectField
                                        open={this.handleCheck2()}
                                        disabled={this.state.showEditButton}
                                        data-test-id="gradeTestBtn"
                                        value={this.state.userGrade}
                                        style={{
                                            height: "50px", borderRadius: "8px",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                            fontSize: "16px", color: "#515B6F", outline: "none",
                                            justifyContent: "center", cursor: "pointer"
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }}
                                        onClick={() => this.openGradeDropdown()}
                                        onChange={(event: any) => this.changeGrade(event)}
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                data-testid="keyboardArrowDownIcon-grade"
                                                onClick={() => this.openGradeDropdown()}
                                                style={{ color: "#94A3B8" }}
                                            />
                                        )}>
                                        {
                                            this.state.gradeListData?.map((value, key) => (
                                                <MenuItem key={key} value={value} >
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </StyledSelectField>
                                </Box>
                            </StyledBox1>
                            <StyledBox1 style={{ display: `${this.hideBoxes()}`, gap: "2%" }}>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > {`${this.addressParameter()}`}
                                    </StyledTypo19>
                                    <StyledTextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="streetTestBtn"
                                        type="text"
                                        value={this.state.userStreetAddress}
                                        style={{
                                            height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center",
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeStreet(event)}
                                        placeholder={`${this.addressParameter()}`}
                                    >
                                    </StyledTextField>
                                </Box>
                            </StyledBox1>
                            <StyledBox1 style={{ display: `${this.hideBoxes()}`, gap: "2%" }}>
                                <Box style={{
                                    display: "flex",
                                    gap: "4px", flexDirection: "column"
                                }}>
                                    <StyledTypo19 style={{
                                        fontFamily: "Manrope", fontSize: "16px", color: "#515B6F",
                                        width: "fit-content", height: "26px", fontWeight: 600,
                                        textAlign: "start",
                                    }}
                                    > City
                                    </StyledTypo19>
                                    <StyledTextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="cityTestBtn"
                                        type="text"
                                        value={this.state.userCity}
                                        style={{
                                            height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center",
                                        }}
                                        sx={{
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeCity(event)}
                                        placeholder="City"
                                    >
                                    </StyledTextField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                    }}
                                    > State
                                    </StyledTypo19>
                                    <StyledSelectField
                                        open={this.handleCheck1()}
                                        disabled={this.state.showEditButton}
                                        data-test-id="stateTestBtn"
                                        value={this.state.userState}
                                        style={{
                                            height: "50px", borderRadius: "8px",
                                            border: "1px solid #D6DDEB", fontFamily: "Manrope", fontWeight: 600,
                                            fontSize: "16px", color: "#515B6F", outline: "none",
                                            justifyContent: "center", cursor: "pointer"
                                        }}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }}
                                        onClick={() => this.openStateDropdown()}
                                        onChange={(event: any) => this.changeUserState(event)}
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                data-testid="keyboardArrowDownIcon-state"
                                                onClick={() => this.openStateDropdown()}
                                                style={{ color: "#94A3B8" }}
                                            />
                                        )}>
                                        {
                                            this.state.stateListData?.map((value, key) => (
                                                <MenuItem key={key} value={value} >
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </StyledSelectField>
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <StyledTypo19 style={{
                                        width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                        fontFamily: "Manrope", fontWeight: 700, fontSize: "16px"
                                    }}
                                    > ZIP Code
                                    </StyledTypo19>
                                    <StyledTextField
                                        disabled={this.state.showEditButton}
                                        data-test-id="codeTestBtn"
                                        type="text"
                                        value={this.state.userZipCode}
                                        style={{
                                            height: "50px", borderRadius: "8px", textAlign: "center",
                                            border: `${this.handleErrorBorder2()}`, fontFamily: "Manrope",
                                            fontSize: "16px", outline: "none",
                                            justifyContent: "center",
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            input: {
                                                color: '#515B6F', fontWeight: 600,
                                                fontSize: "16px", fontFamily: "Manrope"
                                            },
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#010101",
                                            },
                                        }}
                                        onChange={(event) => this.changeZipCode(event)}
                                        placeholder="ZIP Code"
                                    >
                                    </StyledTextField>
                                    {
                                        !this.state.isZipCodevalid &&
                                        <Typography style={{
                                            width: "fit-content", textAlign: "start", color: "#DC2626",
                                            fontFamily: "Inter", fontWeight: 400, fontSize: "12px"
                                        }}
                                        > {this.zipCodeHelperText()}
                                        </Typography>
                                    }
                                </Box>
                            </StyledBox1>
                        </StyledBox27>
                    }
                    {(this.state.showSaveButton && this.state.currentTabIndex == "4") &&
                        <StyledSaveButton
                            data-test-id="saveTestBtn"
                            style={{
                                background: "#5E70DD", width: "205px", height: "43px",
                                borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                                fontFamily: "Roboto", fontWeight: 700, fontSize: "16px",
                                textTransform: "none", alignSelf: "flex-end"
                            }}
                            onClick={() => this.handleSave()}
                        > Save
                        </StyledSaveButton>
                    }
                    {this.callTabsFunction()}
                </StyledBox4>
            </MainBox>
        )
    }

    callTabsFunction() {
        const { currentTabIndex } = this.state;

        if (currentTabIndex === "0") {
            return this.renderBookings();
        } else if (currentTabIndex === "1") {
            return this.renderEarningsHistory();
        } else if (currentTabIndex === "2") {
            return this.renderMessages();
        } else if (currentTabIndex === "3") {
            return this.renderPaymentScreen();
        } else if (currentTabIndex === "5") {
            return this.renderWorkPreferencesAndInterests();
        } else if (currentTabIndex === "7") {
            return this.renderDirectDepositInfo();
        }
    }
}

const webStyles = {

    tabs: {
        color: "#7C8493",
        height: "26px",
        fontFamily: "Manrope",
        fontWeight: "600",
        fontSize: 16,
        textTransform: "none",
        padding: "12px"
    },
    payText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#7C8493"
    },
    payInfo: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "19.12px",
        color: "#02172C"
    },
    payButton: {
        maxWidth: "200px",
        width: "100%",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid #5E70DD",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "21px",
        color: "#5E70DD",
        textTransform: "capitalize" as "capitalize",
    },

    addCardDiv: {
        maxWidth: "700px",
        width: "100%",
        height: "76px",
        border: "1px solid #F2F2F2",
        borderRadius: "16px",
        display: "flex",
        gap: "16px",
        alignItems: "center",
        paddingLeft: "32px"
    },
    cardInfo: {
        maxWidth: "406px",
        width: "100%",
        height: "35px",
        borderRadius: "7px",
        border: "1px solid #F2F2F2",
        display: "flex",
        gap: "25px",
        alignItems: "center"
    },
    cardText: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19.12px",
        letterSpacing: "0.0114em"
    },
    secureText: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "background: #8D9299"
    },
    billInfo: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        color: "#02172C"
    },
    billInfoBox: {
        maxWidth: "406px",
        width: "100%",
        height: "183px",
        borderRadius: "7px",
        border: "1px solid #F2F2F2",
        display: "flex",
        alignItems: "center",
        flexDirection: "column" as "column",
    },
    spanText: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#02172C",
        paddingRight: "26px"
    },
    inputText: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        border: "none"
    },
    contentAlign: {
        width: "85%",
        alignItems: "center",
        display: "flex",
        height: "36px",
        paddingLeft: "61px"
    },
    TypographyStyle1: { fontSize: '14px', fontWeight: 600, fontFamily: "Manrope", lineHeight: "19.12px", color: "#000000" },
    TypographyStyle: { fontSize: '16px', fontWeight: 600, fontFamily: "Manrope", lineHeight: "21.86px", color: "#000000" },
    containerStyle: { width: "100%", display: "flex" },
    innerBoxStyle: { width: '100%', backgroundColor: "rgba(161, 220, 250, 0.3)", borderRadius: '16px' },
    headerBoxStyle: { alignItems: "center", height: "28px", display: 'flex', gap: '16px', color: 'rgba(0, 0, 0, 1)' },
    textFieldStyle: { height: "48px", backgroundColor: 'white', maxWidth: '336px', width: '100%', gap: '20px', border: '1px solid rgba(242, 242, 242, 1)' },
    cardNumberStyle: { height: '48px', fontSize: '16px', display: 'flex', fontWeight: 600, alignItems: 'center' },
    expiryInputStyle: { borderRadius: "8px", border: "none", height: "48px", maxWidth: '56px', width: '100%', backgroundColor: 'white' },
    checkBoxContainerStyle: { paddingTop: "32px", maxWidth: '24px', width: '100%', height: '24px', borderRadius: '3px', display: 'flex', paddingRight: '240px', gap: '10px' },

    serviceDateInEarnings: {
        fontFamily: "Manrope",
        fontWeight: 400,
        color: "rgba(34, 34, 34, 0.9)",
        fontSize: "16px",
    },
    earningsCell: {
        borderBottom: "none",
        fontSize: "16px",
        fontFamily: "Manrope",
        fontWeight: 400,
        color: "rgba(34, 34, 34, 0.9)",
    },
    earningsCellStatus: {
        borderBottom: "none",
    },
    earningsCellMenuIcon: {
        borderBottom: "none",
    },
    earningsHeaderCell: {
        fontSize: "16px",
        fontFamily: "Manrope",
        fontWeight: 600,
        color: "#131313",
    },
    paidContainer: {
        display: "flex",
        height: "30px",
        width: "60px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#5E70DD',
        color: '#FFFFFF',
        borderRadius: '30px',
        padding: '4px 12px',
        fontSize: "12px",
        fontFamily: "Manrope",
        fontWeight: 600,
    },
    rejectedContainer: {
        display: "flex",
        height: "30px",
        width: "60px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#FF9740',
        color: '#FFFFFF',
        borderRadius: '30px',
        padding: '4px 12px',
        fontSize: "12px",
        fontFamily: "Manrope",
        fontWeight: 600,
    },
    billInfoTextDDI: {
        fontWeight: 600, color: "#02172C",
        fontFamily: "Manrope", fontSize: '16px',
    },
    billingInfoDetailsDDI: {
        display: "flex", height: "36px", width: "100%",
        alignItems: "center", paddingRight: "10px",
        borderBottom: "0.5px solid #F2F2F2", paddingLeft: "40px"
    },
    bankAccountTextDDI: {
        fontWeight: 500, color: "#02172C",
        fontFamily: "Manrope", fontSize: '14px',
    }

}
// Customizable Area End
