import React from "react";

// Customizable Area Start
import { Box,Typography, Modal, Button} from "@mui/material";
import { styled } from "@mui/styles"
import { arrowIcon, Close } from "./assets"

// Customizable Area End
// Customizable Area Start
const StyledBox = styled(Box)({
  overflowX: "hidden",
  "@media (max-width: 768px)": {
   display: "flex",
   justifyContent: "center",
   },
})

const AlignDiv = styled(Box)({
"@media (max-width: 768px)": {
  width:"80%"
},
})
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  renderDeleteModal = () => {
    return  <Box style={{
      maxWidth: "600px",
      width:"100%",
      maxHeight: "450px",
      height:"100%",
      borderRadius: "10px",
      backgroundColor:"#FFFFFF",
    }}>
      <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
      <img src={Close.default} onClick={this.closeModal}/>
     </Box>
     <Box style={{display: "flex", flexDirection: "column", gap: `${this.state.error ? "16px" : "40px" }`, maxHeight: "426px",  height: "100%"}}>
     <Box style={{width:"92%", padding: "26px"}}>
     <Box>
      <Typography style={webStyles.logTitle}>Are you sure you want to delete your account?</Typography>
      <Typography style={{...webStyles.logText, paddingBottom: "24px"}}>This action can’t be undone and all the data stored in your account won’t be accessible anymore.</Typography>
      {this.state.errorStatus && this.renderAlert()}
     </Box>
     <Box>
     <Box style={{marginTop:"16px"}}>
        <Typography style={webStyles.passText}>Enter your password</Typography>
        <input
        type="text"
        name="oldPass"
        data-test-id = "PassId"
        value = {this.state.oldPassword}
        onChange={(event)=> this.handleText(event)}
        placeholder={ this.state.error ? "wrong password": ""}
        style={{ ...webStyles.textBox, ...webStyles.wrongPass, color: `${this.state.error ? "#FD7D19" : "" }`}}
        />
        </Box>
        </Box>
     </Box>
        <Box style={{border:"1px solid #E2E8F0", width:"99.5%"}}></Box>
        <Box>
          <Box style={{display:"flex", justifyContent:"flex-end", paddingRight: "20px", alignItems: "center"}}>
            <Box style={{display:"flex",gap:"16px"}}>
            <Button style={webStyles.cancelBtn} onClick={this.closeModal}>Cancel</Button>
            <Button style={{...webStyles.btn,width:"154.95px",padding:"0px"}} onClick = {this.deleteAccount}>Delete account</Button>
            </Box>
          </Box>
        </Box>
        </Box>
     
     </Box>
  }

  renderLogOutModal = () => {
    return <Box style={{
      maxWidth: "600px",
      width:"100%",
      maxHeight: "252px",
      height:"100%",
      borderRadius: "10px",
      backgroundColor:"#FFFFFF",
    }}>
      <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
      <img src={Close.default} onClick={this.closeModal}/>
     </Box>
     <Box style={{  display: "flex", flexDirection: "column", gap: "40px",  height: "100%", maxHeight: "228px"}}>
      <Box style={{paddingLeft:"26px", paddingRight:"26px",}}>
      <Typography style={{...webStyles.logTitle, width: "540px"}} >Are you sure you want to log out of your account?</Typography>
      </Box>
      <Box style={{border:"1px solid #E2E8F0", width:"99.5%"}}></Box>
      <Box>
      <Box style={{display:"flex", justifyContent:"flex-end", paddingRight: "20px"}}>
            <Box style={{display:"flex",gap:"16px"}}>
            <Button style={{...webStyles.cancelBtn,width:"143.88px"}} onClick={this.closeModal}>Cancel</Button>
            <Button style={{...webStyles.btn,width:"143.88px",padding:"0px"}} onClick={this.logout}>Log out</Button>
            </Box>
          </Box>
      </Box>
      </Box>
     </Box>
  }

  renderAlert = () => {
    setTimeout(()=>{
        this.setState({ errorStatus: false, error: false})
    },3000)
    return <Box style={{width:"360px", display:"flex", justifyContent:"center", alignItems:"center" ,height:"42px", borderLeft:"4px solid #FD7D19", borderRadius:"4px", background: "#FEE2E2"}}>
    <Typography style={{color:"#FD7D19",fontFamily: "Inter",fontSize: "12px",fontWeight: 400,lineHeight: "18px",textAlign: "left",
    }}>{this.state.errorMsg}</Typography>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledBox >            
      {(this.state.currentTab === 1) && 
      <AlignDiv>
          <Box style={{marginTop: "10px", width:"100%", padding:"0,15"}}>

          <Box style={webStyles.boxWidth}>
            <Typography style={webStyles.commonText}>Change Password</Typography>
            <img src={arrowIcon.default} 
             onClick={this.openModal}
                style={{transform: 'rotate(272deg)'}} 
                />
          </Box>
          <Box style={webStyles.boxWidth}>
            <Typography style={webStyles.commonText}>Terms and Conditions</Typography>
            <img src={arrowIcon.default} data-test-id="termsID" onClick={()=>  this.goTermsConditions() } 
                style={{transform: 'rotate(272deg)'}} 
                />
          </Box>
          <Box style={webStyles.boxWidth}>
            <Typography style={{...webStyles.commonText, color:"#FF8219"}}>Delete Account</Typography>
            <img src={arrowIcon.default} 
                style={{transform: 'rotate(272deg)'}} 
                onClick={this.openDeleteModal.bind(this,"DELETE")}
                />
          </Box>

          <Box style={webStyles.boxWidth}>
            <Typography style={{...webStyles.commonText, color:"#FF8219"}}>Log Out</Typography>
            <img src={arrowIcon.default} onClick={this.openDeleteModal.bind(this,"LOGOUT")}
                style={{transform: 'rotate(272deg)',}} 
                />
          </Box>
          </Box>
      </AlignDiv> }
      <Modal
      open={this.state.isOpen}
      onClose={this.closeModal}
      style={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <Box style={{
          maxWidth: "600px",
          width:"100%",
          borderRadius: "10px",
          backgroundColor:"#FFFFFF",
        }}>
          <Box style={{width: "97.5%", justifyContent: "flex-end", display:"flex", marginTop:"10px"}}>
          <img src={Close.default} onClick={this.closeModal}/>
         </Box>
          <Box style={{
             display: "flex",
             flexDirection: "column",
              gap: "24px",
             padding: "20px"
          }}>
            <Typography style={webStyles.changePass}>Change password</Typography>
            <Typography >You can update your password to keep your account secure.</Typography>
            {this.state.errorStatus && this.renderAlert()}
            <Box>
              <Box>
              <Typography style={webStyles.passText}>Old password</Typography>
              <input
              type="text"
              name="oldPass"
              data-test-id = "oldPassId"
              value = {this.state.oldPassword}
              onChange={(event)=> this.handleText(event)}
              placeholder={ this.state.error ? "wrong password": ""}
              style={{ ...webStyles.textBox, ...webStyles.wrongPass, color: `${this.state.error ? "#FD7D19" : "" }`}}
              />
              </Box>
              <Box style={{marginTop: "10px" }}>
              <Typography style={webStyles.passText}>New password</Typography>
              <input
              type="text"
              name="newPass"
              data-test-id ="newPassId"
              onChange={(event)=> this.handleText(event)}
              value = {this.state.newPassword}
              style={webStyles.textBox}
              />
              </Box>
            </Box>
          </Box>
          <Box>

          </Box>
        <Box style={{border:"1px solid #E2E8F0", width:"99.5%", marginTop:"24px"}}></Box>
        <Box style={{paddingRight:"24px", display: "flex", justifyContent:"flex-end", paddingTop:"40px", paddingBottom:"30px"}}>
          <Button style={webStyles.btn} onClick={this.resetPasswordApi}>Change password</Button>

        </Box>
        </Box>

      </Modal>

      <Modal
      open={this.state.deleteModal}
      onClose={this.closeModal}
      style={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <>
         {this.state.renderStatus === "DELETE" && this.renderDeleteModal()}
         {this.state.renderStatus === "LOGOUT" && this.renderLogOutModal()}
        </>

         </Modal>
      </StyledBox>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const webStyles = {
commonText: {
  fontFamily: "Manrope",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.10px",
  color: "#000000"
},
changePass: {
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.11px",
  color: "#0F172A"
},
text: {
  fontFamily: "Manrope",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#0F172A"
},
textBox: {
  maxWidth: "520px",
  width: "100%",
  height: "100%",
  maxHeight: "44px",
  padding: "10px 8px 10px 8px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1"
},
  btn: {
  width: "205px",
  height: "43px",
  padding: "12px 24px 12px 24px",
  borderRadius: "10px",
  color: "#FFFFFF",
  backgroundColor: "#5E70DD",
  textTransform: "capitalize" as "capitalize",
  fontFamily: "Manrope",
  fontSize: "15px",
  fontWeight: 700,
  lineHeight: "25.6px"

},
 boxWidth: {
  display:"flex",
  justifyContent:"space-between",
  maxWidth:"1187px",
  height:"56px",
  borderBottom:"1px solid #E5E5E5",
  width:"100%",
  alignItems:"center"
},
  passText: {
  fontFamily: "Manrope",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  color: "#334155"
 },
headerText: {
  width: "366px",
    height: "46px",
    borderRadius: "19px",
    backgroundColor: "#F4F4F4",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px"
},
cancelBtn: {
  width:"154.95px",
  height: "43px",
  padding: "12px 24px 12px 24px",
  borderRadius: "10px",
  border: "1px solid #5E70DD",
  color: "#5E70DD",
  textTransform: "capitalize" as "capitalize",
  fontFamily: "Manrope",
  fontWeight: 700,
  fontSize: "16px",
  LineHeight: "25.6px"
},
logTitle:{
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.11px",
  color: "#0F172A",
  width: "520px"

},
logText: {
  fontFamily: "Manrope",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  color: "#0F172A",
  marginTop:"16px"
},
profileText: {
  fontFamily: "Manrope",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "28.8px",
  color:"#25324B",
  marginTop:"32px"
},
wrongPass: {
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14.52px",
}
}



// Customizable Area End
